import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Image, Flex, View } from "@aws-amplify/ui-react";
import Images from '../../constants/Images';
import useDeviceTypes from "../../hooks/useDeviceTypes";


export default function Nav(props) {

    const { width, isMobileTablet } = useDeviceTypes();
    useEffect(() => {}, []);

    return (
        <Flex alignItems={'center'} width={width} justifyContent={'center'} direction={'row'} height={'10vh'}>
            <Flex width={'90%'} alignSelf={'center'} justifyContent={isMobileTablet ? 'center': 'space-between'}>
                {isMobileTablet ? null : <View />}
                <Image src={Images.logo} height={60} />
            </Flex>
        </Flex>
    )
}