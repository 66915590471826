
import { useState, useEffect } from "react";
import { useWindowDimensions } from 'react-native';

export default function useDeviceTypes(){

    const { height, width } = useWindowDimensions();

    const mobile = () => {
        if(width < 480) return true
        else return false;
    }

    const tablet = () => {
        if((width > 481) && (width < 768)) return true
        else return false;
    }

    const mobileTablet = () => {
        if(width < 768) return true
        else return false;
    }

    const small = () => {
        if((width > 769) && (width < 1024)) return true
        else return false;
    }
    const large = () => {
        if(width > 1201) return true
        else return false;
    }
    
    const [ isMobile, setIsMobile] = useState(mobile());

    const [ isTablet, setIsTablet] = useState(tablet());

    const [ isMobileTablet, setIsMobileTablet] = useState(mobileTablet());

    const [ isSmallScreen, setIsSmallScreen] = useState(small());

    const [ isLargeScreen, setIslargeScreen] = useState(large());

    

    useEffect(() => {
        setIsMobile(mobile());
        setIsTablet(tablet());
        setIsMobileTablet(mobileTablet());
        setIsSmallScreen(small());
        setIslargeScreen(large());
    },[width])


    return {
        isMobile,
        isTablet,
        isMobileTablet, 
        isSmallScreen,
        isLargeScreen,
        height,
        width,
    }

}