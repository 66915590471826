export default {
    //ATTAleckCd
    HEADLINES: 'ATTAleckCd-Rg',
    BODY: 'ATTAleckSans-Rg',
    'ATTAleckCd-Bold':  'ATTAleckCd-Bold',
    'ATTAleckCd-BdIt': 'ATTAleckCd-BdIt',
    'ATTAleckCd-Blk': 'ATTAleckCd-Blk',
    'ATTAleckCd-BlkIt': 'ATTAleckCd-BlkIt',
    'ATTAleckCd-It': 'ATTAleckCd-It',
    'ATTAleckCd-Lt': 'ATTAleckCd-Lt',
    'ATTAleckCd-LtIt': 'ATTAleckCd-LtIt',
    'ATTAleckCd-Md': 'ATTAleckCd-Md',
    'ATTAleckCd-MdIt': 'ATTAleckCd-MdIt',
    'ATTAleckCd-Rg': 'ATTAleckCd-Rg',
    //ATTAleckSans
    'ATTAleckSans-Bd': 'ATTAleckSans-Bd',
    'ATTAleckSans-BdIt': 'ATTAleckSans-BdIt',
    'ATTAleckSans-Blk': 'ATTAleckSans-Blk',
    'ATTAleckSans-BlkIt': 'ATTAleckSans-BlkIt',
    'ATTAleckSans-It': 'ATTAleckSans-It',
    'ATTAleckSans-Lt': 'ATTAleckSans-Lt',
    'ATTAleckSans-LtIt': 'ATTAleckSans-LtIt',
    'ATTAleckSans-Md': 'ATTAleckSans-Md',
    'ATTAleckSans-MdIt': 'ATTAleckSans-MdIt',
    'ATTAleckSans-Rg': 'ATTAleckSans-Rg',
    'ATTAleckSans-Th': 'ATTAleckSans-Th',
    'ATTAleckSans-ThIt': 'ATTAleckSans-ThIt',
}