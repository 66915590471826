import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex, Text } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import useMarket from "../../../hooks/useMarket";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";
import Colors from "../../../constants/Colors";
import ScreenNames from "../../../constants/ScreenNames";

export default function CancelConfirmation(props) {

    const navigate = useNavigate();

    const { state } = useLocation();
    const { attRedirect } = useMarket();
    const [product] = useState(state?.canceledSubscription?.items?.data[0]?.product || state.product || {});
    const [status] = useState(state.status);

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].cancelConfirmationTitle;
    }, []);

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                {!status && <Header title={Strings.GOOGLE[Language].cancelConfirmationTitle} subtitle={Strings.GOOGLE[Language].cancelConfirmationSubTitle.replace('<product>', product?.descriptions?.data[0]?.description || '')}/>}
                {status === 400 && <Header title={Strings.GOOGLE[Language].cancelConfirmationTitle} subtitle={Strings.GOOGLE[Language].cancelSubscription400.replace('<product>', product?.descriptions?.data[0]?.description || '')}/>}
                {status === 409 && <Header title={Strings.GOOGLE[Language].cancelConfirmationTitle} subtitle={Strings.GOOGLE[Language].cancelSubscription409.replace('<product>', product?.descriptions?.data[0]?.description || '')}/>}

                <Flex direction={'column'} marginTop={50} width={350} alignSelf='center'>
                    <OutlineButton
                        children={Strings.GOOGLE[Language].continue}
                        onClick={attRedirect}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}