import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, RadioGroupField, Radio, Text, Link } from "@aws-amplify/ui-react";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import TextButton from "../../../components/Buttons/TextButton";
import useMarket from "../../../hooks/useMarket";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import Fonts from "../../../constants/Fonts";

export default function GoogleError(props) {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [ apiLoading, setApiLoading]= useState(false);
    const { height, width, isMobile } = useDeviceTypes();

    const { googleAuth, getCurrentPartnerSub, cancelSubscription } = useMarket();

    const [choices, setChoices] = useState([
        {
            id: '1',
            name: Strings.GOOGLE[Language].useAnotherGoogleAccount,
        },
        {
            id: '2',
            name: Strings.GOOGLE[Language].cancelOtherSubscription,
        },
    ]);

    const [choice, setChoice] = useState('');
    const [error, setError] = useState(false);
    const [errorResponse, setErrorResponse] = useState('');

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].googleErrorTitle;
    }, []);

    useEffect(() => {
        
        console.log({ state })
    }, [state]);

    const handleErrorResponse = () => {
        switch (state?.error?.validationErrors[0]?.message) {
            case 'ERROR_CODE_SUBSCRIPTION_REJECTED_ALREADY_SUBSCRIBED':
                return (
                    <Text>{`La cuenta de Google que quieres usar ya tiene una suscripción activa con Google One. Por favor sigue las instrucciones detalladas en el`} <Link color={'#0000EE'} href="https://support.google.com/googleone/answer/9056360#partner-cancel" target={'_blank'}>{`enlace`}</Link> {`para cancelar tu plan actual de Google One.`}</Text>
                )
            case 'ERROR_CODE_SUBSCRIPTION_REJECTED_SUBSCRIPTION_INELIGIBLE':
                return (
                    <Text>{`Las cuentas de Google Workspace y las cuentas de menores de edad no son elegibles para Google One. Por favor utiliza una cuenta personal de Google que cumpla con los requisitos para continuar con la activación. `}</Text>
                )
            case 'ERROR_CODE_SUBSCRIPTION_REJECTED_PROMOTION_INELIGIBLE':
                return (
                    <Text>{`La Cuenta de Google que quieres usar no es elegible para obtener el primer mes de servicio incluido con AT&T. Consulta aquí los `} <Link color={'#0000EE'} href="https://www.att.com.mx/entretenimiento/google-one" target={'_blank'}>{`Términos y Condiciones.`}</Link></Text>
                )
        
            default:
                return ''
        }
    }

    const next = async() => {

        if(!choice) {
            setError(true);
            return false;
        }
        setError(false);

        setApiLoading(true);

        if(choice === '1') await googleAuth();
        else {
            const { partnerSubscription } = await getCurrentPartnerSub();
            await cancelSubscription({ id: partnerSubscription.subscriptionId, dryRun: true, disentitle: true });
        }

        setApiLoading(false);

        navigate(ScreenNames.GOOGLE.ManageSubscription);
    }

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].googleErrorTitle} subtitle={Strings.GOOGLE[Language].googleErrorSubTitle}/>
                {state?.error?.validationErrors[0]?.message && <Flex direction={'row'} width={isMobile ? width * .75 : 500} alignSelf={'center'} justifyContent={'center'} textAlign={'center'} fontSize={20} fontFamily={Fonts["ATTAleckCd-Bold"]} >
                    {handleErrorResponse()}
                </Flex>}
                <Flex marginTop={30}>
                    <RadioGroupField
                        gap={40}
                        size={'large'}
                        errorMessage={Strings.GOOGLE[Language].fieldRequired} 
                        hasError={error}
                        value={choice}                        
                        fontSize={20}
                        onChange={(e) => setChoice(e.target.value)}>
                        {_.map(choices, i => <Radio key={i.id} value={i.id}>{i.name}</Radio>)}
                    </RadioGroupField>
                </Flex>
                <Flex direction={'column'} marginTop={100}>
                    <OutlineButton
                        disabled={apiLoading}
                        isLoading={apiLoading}
                        children={Strings.GOOGLE[Language].continue}
                        onClick={next}
                        />
                    <TextButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].cancel}
                        onClick={() => navigate(ScreenNames.GOOGLE.ManageSubscription)}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}