import Home from "../screens/Home";

//HBO
import HBO_Home from "../screens/HBO";
import HBO_Request from "../screens/HBO/Request";
import HBO_Error404 from "../screens/HBO/Error404";

//Google One
import G_Store from "../screens/GoogleOne/Store";
import G_SelectProduct from "../screens/GoogleOne/SelectProduct";
import G_AcceptTerms from "../screens/GoogleOne/AcceptTerms";
import G_PurchaseConfirmation from "../screens/GoogleOne/PurchaseConfirmation";
import G_GeneralFailure from "../screens/GoogleOne/GeneralFailure";
import G_ManageSubscription from "../screens/GoogleOne/ManageSubscription";
import G_CancelSubscription from "../screens/GoogleOne/CancelSubscription";
import G_CancelConfirmation from "../screens/GoogleOne/CancelConfirmation";
import G_CancelConfirmationOther from "../screens/GoogleOne/CancelConfirmationOther";
import G_Authentication from "../screens/GoogleOne/Authentication";
import G_AuthenticationFailure from "../screens/GoogleOne/AuthenticationFailure";
import G_Purchase from "../screens/GoogleOne/Purchase";
import G_ChangeSubscription from "../screens/GoogleOne/ChangeSubscription";
import G_ChangeSubscriptionConfirmation from "../screens/GoogleOne/ChangeSubscriptionConfirmation";
import G_ActivateSubscription from "../screens/GoogleOne/ActivateSubscription";
import G_LoginWithGoogle from "../screens/GoogleOne/LoginWithGoogle";
import G_GoogleError from "../screens/GoogleOne/GoogleError";
import G_ActivationConfirmation from "../screens/GoogleOne/ActivationConfirmation";
import G_ConfirmCancellation from "../screens/GoogleOne/ConfirmCancellation";
import G_Request from "../screens/GoogleOne/Request";
import G_Error from "../screens/GoogleOne/Error";
import G_Error404 from "../screens/GoogleOne/Error404";

//Paramount
import PRM_LandingPage from "../screens/Paramount/LandingPage";
import PRM_ManageSubscription from "../screens/Paramount/ManageSubscription";
import PRM_CancelSubscription from "../screens/Paramount/CancelSubscription";
import PRM_PurchaseInstead from "../screens/Paramount/Purchase";
import PRM_CancelConfirmation from "../screens/Paramount/CancelConfirmation";
import PRM_AcceptTerms from "../screens/Paramount/AcceptTerms";
import PRM_PurchaseConfirmation from "../screens/Paramount/PurchaseConfirmation";
import PRM_GeneralFailure from "../screens/Paramount/GeneralFailure";
import PRM_AuthenticationFailure from "../screens/Paramount/AuthenticationFailure";
import PRM_Error404 from "../screens/Paramount/Error404";

const General = {
    Home,
}
const HBO = {
    HBO_Home,
    HBO_Request,
    HBO_Error404,
}
const PRM = {
    PRM_LandingPage,
    PRM_ManageSubscription,
    PRM_CancelSubscription,
    PRM_PurchaseInstead,
    PRM_CancelConfirmation,
    PRM_AcceptTerms,
    PRM_PurchaseConfirmation,
    PRM_GeneralFailure,
    PRM_AuthenticationFailure,
    PRM_Error404,
}

const GOOGLE = {
    G_Store,
    G_SelectProduct,
    G_AcceptTerms,
    G_PurchaseConfirmation,
    G_GeneralFailure,
    G_ManageSubscription,
    G_CancelConfirmation,
    G_CancelSubscription,
    G_Authentication,
    G_AuthenticationFailure,
    G_Purchase,
    G_ChangeSubscription,
    G_ChangeSubscriptionConfirmation,
    G_ActivateSubscription,
    G_LoginWithGoogle,
    G_GoogleError,
    G_ActivationConfirmation,
    G_ConfirmCancellation,
    G_CancelConfirmationOther,
    G_Request,
    G_Error,
    G_Error404,
}

export default { General, HBO, GOOGLE, PRM };