import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";

export default function Request(props) {

    const navigate = useNavigate();
    
    const params = new URLSearchParams(window.location.search);
    const [code, setCode] = useState(params.get("code"));

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].requestTitle;
    }, []);

    useEffect(() => {
        if(!code) navigate(ScreenNames.GOOGLE.GeneralFailure);
    },[]);

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].requestTitle} subtitle={code}/>                
            </Flex> 
        </Container>
    )
}