import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { View, Button } from "@aws-amplify/ui-react";
import Strings from "../../constants/Strings";
import Language from "../../utils/Language";
import TextDimensions from "../../constants/TextDimensions";
import Fonts from '../../constants/Fonts';
import Colors from "../../constants/Colors";
import { ThreeDots } from  'react-loader-spinner';

export default function Spinner(props) {
    useEffect(() => {}, []);

    return (
        <ThreeDots
        {...props}
        height = "60"
        width = "60"
        radius = "9"
        color = {Colors.primary}
        ariaLabel = 'three-dots-loading'
        />
    )
}