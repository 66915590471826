
const GOOGLE = {
    English: {
        signIn: 'Sign In',
        emailAddress: 'Email Address',
        password: 'Password',
        submit: 'Submit',
    
        //
        hbo: `HBO`,
        googleOne: `Google One`,
    
        //Home
        homeTitle: `Home`,
        homeSubTitle: ``,
        
        //Store
        storeTitle: 'HBO',
        storeSubTitle: '',
    
        //Purchase [Select product]
        purchaseTitle: 'Subscription',
        purchaseSubTitle: 'Select Product',
    
        //Accept Terms
        acceptTermsTitle: `Pre-Purchase Confirmation`,
        acceptTermsSubTitle: `Your monthly subscription for <product> will cost <price>`,
    
        //Purchase Confirmation
        purchaseConfirmationTitle: `Purchase Confirmation`,
        purchaseConfirmationSubTitle: `Thank you for your purchase. Please wait for SMS to activate your subscription.`,
    
        //Manage Subscription
        manageSubscriptionTitle: `Manage Subscription`,
        manageSubscriptionSubTitle: `Review and manage your subscription details`,
    
        //Cancel Subscription
        cancelSubscriptionTitle: `Cancel Subscription`,
        cancelSubscriptionSubTitle: `Cancel your current subscription`,
        cancelSubscription400: `Tu suscripción de <product> ha sido cancelada.`,
        cancelSubscription409: `Tu suscripción de <product> ha sido cancelada.`,

        //Google Error
        googleErrorTitle: `Google Error`,
        googleErrorSubTitle: `An error occured while activating your subscription in Google`,
    
        //Activation Confirmation
        activationConfirmationTitle: `Activation Confirmation`,
        activationConfirmationSubTitle: `Thank you for activating your subscription`,
        activationConfirmationThirdTitle: `Para continuar tu activación, es necesario que otorgues permisos a AT&T MX para administrar tu suscripción de Google One`,
    
        //Cancel Confirmation
        cancelConfirmationTitle: `Cancel Confirmation`,
        cancelConfirmationSubTitle: `Your subscription for <product> was successfully cancelled`,
    
        //Activate Subscription
        activateSubscriptionTitle: `Activate Subscription`,
        activateSubscriptionSubTitle: `Let's activate your subscription`,
    
        //Authentication Failure
        authenticationFailureTitle: `M1 Authentication Failure`,
        authenticationFailureSubTitle: `There was an issue with your request`,
    
        //Purchase Instead
        purchaseInsteadTitle: `Purchase Instead`,
        purchaseInsteadSubTitle: `You don’t have a subscription to manage, please click below to purchase your subscription`,
    
        //Cancel Confirmation Other
        cancelConfirmationOtherTitle: `Cancel Confirmation`,
        cancelConfirmationOtherSubTitle: `Your subscription was successfully cancelled`,
    
        //Change Subscription
        changeSubscriptionTitle: `Change Subscription`,
        changeSubscriptionSubTitle: ``,
    
        //Change Confirmation Subscription
        changeSubscriptionConfirmationTitle: `Change Confirmation`,
        changeSubscriptionConfirmationSubTitle: `Your change was successful`,
    
        //General Failure
        generalFailureTitle: `General Failure`,
        generalFailureSubTitle: `There was an issue with your request.`,
    
        //Request
        requestTitle: `Request`,
        requestSubTitle: ``,
    
        //General
        purchase: 'Purchase',
        manageSubscription: 'Manage Subscription',
        loading: 'Loading',
        selectProduct: 'Select Product',
        acceptTerms: `I accept the terms and conditions`,
        acceptG1Terms: `Acepto los Términos y Condiciones de Google One`,
        okay: 'Okay',
        confirm: 'Confirm',
        cancel: 'Cancel',
        change: `Change`,
        activate: `Activate`,
        cancelSubscription: `Cancel Subscription`,
        changeSubscription: `Change Subscription`,
        currentSubscription: `Current Subscription`,
        goBack: `Go Back`,
        continue: `Continue`,
        useAnotherGoogleAccount: `Use another Google Account`,
        cancelOtherSubscription: `Cancel other subscription`,
        subscriptionActivated: `Subscription Activated`,
        serviceWillRemainActive: `Your service will remain active until`,
        activateSubscription: `Activate Subscription`,
        tryAgain: `Try Again`,
        customerWillNotBeCharged: `Customer will not be charged`,
        subscriptionCancelled: `Subscription Cancelled`,
        backToHome: `Back to Home`,
        newSubscription: `New Subscription`,
        selectNewSubscription: `Select a new subscription`,
        modify: `Modify your plan`,
        continue: 'Continue',
        
        //Errors
        fieldRequired: `This is a required field.`,
    
    },
    Spanish: {
    
        signIn: 'Sign In',
        emailAddress: 'Email Address',
        password: 'Password',
        submit: 'Submit',
    
        //
        hbo: `HBO`,
        googleOne: `Google One Tienda Digital​`,
    
        //Home
        homeTitle: `Home`,
        homeSubTitle: ``,
        
        //Store
        storeTitle: 'HBO',
        storeSubTitle: '',
    
        //Purchase [Select product]
        purchaseTitle: 'Subscription',
        purchaseSubTitle: `Selecciona tu plan`,
    
        //Accept Terms
        acceptTermsTitle: `Confirma tu plan`,
        acceptTermsSubTitle: `Tu suscripción mensual de <product> costará <price>`,
    
        //Purchase Confirmation
        purchaseConfirmationTitle: `¡Bienvenido a Google One!​`,
        purchaseConfirmationSubTitle: `Gracias por suscribirte.​ Recibirás un mensaje de texto ​para activar tu suscripción.​`,
    
        //Manage Subscription
        manageSubscriptionTitle: `Administra tu suscripción`,
        manageSubscriptionSubTitle: `Review and manage your subscription details`,
    
        //Cancel Subscription
        cancelSubscriptionTitle: `Cancela tu suscripción`,
        cancelSubscriptionSubTitle: `Cancel your current subscription`,
        cancelSubscription400: `Tu suscripción de <product> ha sido cancelada.`,
        cancelSubscription409: `Tu suscripción de <product> ha sido cancelada.`,

        //Google Error
        googleErrorTitle: `¡Disculpa!​`,
        googleErrorSubTitle: `Ocurrió un error en la activación de tu suscripción de Google​`,
    
        //Activation Confirmation
        activationConfirmationTitle: `¡Bienvenido a Google One!​`,
        activationConfirmationSubTitle: `Gracias por activar tu suscripción​`,
        activationConfirmationThirdTitle: `Para continuar tu activación, es necesario que otorgues permisos a AT&T MX para administrar tu suscripción de Google One`,

        //Cancel Confirmation
        cancelConfirmationTitle: `Suscripción cancelada`,
        cancelConfirmationSubTitle: `Tu suscripción de <product> ha sido cancelada.`,
    
        //Activate Subscription
        activateSubscriptionTitle: `Activa tu suscripción​`,
        activateSubscriptionSubTitle: ``,
    
        //Authentication Failure
        authenticationFailureTitle: `¡Disculpa!​`,
        authenticationFailureSubTitle: `Tuvimos una falla con tu solicitud.​`,
    
        //Purchase Instead
        purchaseInsteadTitle: `Aún no tienes una suscripción`,
        purchaseInsteadSubTitle: `Da click abajo para suscribirte.​`,
    
        //Cancel Confirmation Other
        cancelConfirmationOtherTitle: `Suscripción cancelada`,
        cancelConfirmationOtherSubTitle: `Tu suscripción a Google One ha sido cancelada.`,
    
        //Change Subscription
        changeSubscriptionTitle: `Cambia tu suscripción​`,
        changeSubscriptionSubTitle: ``,
    
        //Change Confirmation Subscription
        changeSubscriptionConfirmationTitle: `Tu plan fue cambiado​`,
        changeSubscriptionConfirmationSubTitle: `¡Disfrútalo!`,
    
        //General Failure
        generalFailureTitle: `¡Disculpa!​`,
        generalFailureSubTitle: `Hubo un problema.​`,
    
        //Request
        requestTitle: `Request`,
        requestSubTitle: ``,
    
        //General
        purchase: `Suscríbete​`,
        manageSubscription: `Administra tu suscripción​`,
        loading: 'Loading',
        selectProduct: `Selecciona tu plan`,
        acceptTerms: `Acepto los Términos y Condiciones`,
        acceptG1Terms: `Acepto los Términos y Condiciones de Google One`,
        okay: `Confirmar​`,
        confirm: `Confirmar​`,
        cancel: `Cancelar​`,
        change: `Cambiar`,
        activate: `Activar​`,
        cancelSubscription: `Cancela tu suscripción​`,
        changeSubscription: `Cambia tu suscripción​`,
        currentSubscription: `Suscripción actual​`,
        goBack: `Regresar`,
        continue: `Continuar`,
        useAnotherGoogleAccount: `Usar otra cuenta Google​`,
        cancelOtherSubscription: `Cancelar otra suscripción​`,
        subscriptionActivated: `Suscripción activada`,
        serviceWillRemainActive: `Tu suscripción seguirá activa hasta el​`,
        activateSubscription: `Activa tu suscripción`,
        tryAgain: `Intentar de nuevo`,
        customerWillNotBeCharged: `Customer will not be charged`,
        subscriptionCancelled: `Suscripción Cancelada`,
        backToHome: `Back to Home`,
        newSubscription: `New Subscription`,
        selectNewSubscription: `Elige tu nuevo plan​`,
        modify: `Modifica tu plan`,
        continue: 'Continuar',
        
        //Errors
        fieldRequired: `Este es un campo obligatorio.`,
    }
};

const HBO = {
    English: {
        title: 'AT&T Purchase Max',
        //Home Page
        homeTitle: `You are just a few steps away from starting your Max experience!`,
        // homeSubTitle: `If this is the first time you contract Max Standard with AT&T, you will pay $199 pesos per month. If you are back pay $199 pesos per month.`,
        homeSubTitle: `Sign up for Max Standard with your AT&T number and pay $199 pesos per month.`,

        //General
        product: 'Max',
        cancel: 'Cancel',
        continue: 'Continue',
    },
    Spanish: {
        title: 'AT&T Purchase Max',
        //Home Page
        homeTitle: `¡Estas a unos pasos de comenzar tu experiencia en Max!`,
        // homeSubTitle: `Si es la primera vez que contratas Max Estándar con AT&T, pagarás $199 pesos mensuales. Si estás de regreso paga $199 pesos al mes.`,
        homeSubTitle: `Contrata Max Estándar con tu número AT&T y paga $199 pesos mensuales`,

        //General
        product: 'Max',
        cancel: 'Cancelar​',
        continue: 'Continuar',
    }
};

const PRM = {
    English: {
        landingTitle: 'Paramount+ Tienda Digital',
        landingSubTitle: '',

        //Purchase [Select product]
        purchaseTitle: 'Subscription',
        purchaseSubTitle: 'Select Product',

        //Purchase Confirmation
        purchaseConfirmationTitle: `Welcome to Paramount+`,
        purchaseConfirmationSubTitle: `Thank you for your purchase. Please wait for SMS to activate your subscription.`,
    
        //Manage Subscription
        manageSubscriptionTitle: `Manage Subscription`,
        manageSubscriptionSubTitle: ``,

        //Cancel Subscription
        cancelSubscriptionTitle: `Cancel Subscription`,
        cancelSubscriptionSubTitle: ``,

        //Purchase Instead
        purchaseInsteadTitle: `Purchase Instead`,
        purchaseInsteadSubTitle: `You don’t have a subscription to manage, please click below to purchase your subscription`,

        //Cancel Confirmation
        cancelConfirmationTitle: `Cancel Confirmation`,
        cancelConfirmationSubTitle: `Your subscription for <product> was successfully cancelled`,
        cancelConfirmationThirdTitle: `Tu suscripción seguirá activa por el periodo pagado`,

        //Accept Terms
        acceptTermsTitle: `Pre-Purchase Confirmation`,
        acceptTermsSubTitle: `Your monthly subscription for <product> will cost <price>`,

        //Purchase Confirmation
        purchaseConfirmationTitle: `Purchase Confirmation`,
        purchaseConfirmationSubTitle: `Thank you for your purchase. Please wait for SMS to activate your subscription.`,

        //General Failure
        generalFailureTitle: `General Failure`,
        generalFailureSubTitle: `There was an issue with your request.`,

        //Authentication Failure
        authenticationFailureTitle: `M1 Authentication Failure`,
        authenticationFailureSubTitle: `There was an issue with your request`,

        //General
        purchase: 'Purchase',
        manageSubscription: 'Manage Subscription',
        loading: 'Loading',
        selectProduct: 'Select Product',
        acceptTerms: `I accept the terms and conditions`,
        okay: 'Okay',
        confirm: 'Confirm',
        cancel: 'Cancel',
        change: `Change`,
        activate: `Activate`,
        cancelSubscription: `Cancel Subscription`,
        changeSubscription: `Change Subscription`,
        currentSubscription: `Current Subscription`,
        goBack: `Go Back`,
        continue: `Continue`,
        useAnotherGoogleAccount: `Use another Google Account`,
        cancelOtherSubscription: `Cancel other subscription`,
        subscriptionActivated: `Subscription Activated`,
        serviceWillRemainActive: `Your service will remain active until`,
        activateSubscription: `Activate Subscription`,
        confirmSubscription: `Confirm Subscription`,
        tryAgain: `Try Again`,
        customerWillNotBeCharged: `Customer will not be charged`,
        subscriptionCancelled: `Subscription Cancelled`,
        backToHome: `Back to Home`,
        newSubscription: `New Subscription`,
        selectNewSubscription: `Select a new subscription`,
        modify: `Modify your plan`,
        continue: 'Continue',
        
        //Errors
        fieldRequired: `This is a required field.`,
    },
    Spanish: {
        landingTitle: 'Paramount+ Tienda Digital',
        landingSubTitle: '',

        //Purchase [Select product]
        purchaseTitle: 'Subscription',
        purchaseSubTitle: `Selecciona tu plan`,

        //Purchase Confirmation
        purchaseConfirmationTitle: `¡Bienvenido a Paramount+!​`,
        purchaseConfirmationSubTitle: `Gracias por suscribirte.​ Recibirás un mensaje de texto ​para activar tu suscripción.​`,
    
        //Manage Subscription
        manageSubscriptionTitle: `Administra tu suscripción`,
        manageSubscriptionSubTitle: ``,

        //Cancel Subscription
        cancelSubscriptionTitle: `Cancela tu suscripción`,
        cancelSubscriptionSubTitle: ``,

        //Purchase Instead
        purchaseInsteadTitle: `Aún no tienes una suscripción`,
        purchaseInsteadSubTitle: `Da click abajo para suscribirte.​`,

         //Cancel Confirmation
        cancelConfirmationTitle: `Suscripción cancelada`,
        cancelConfirmationSubTitle: `Tu suscripción de Paramount+ ha sido cancelada.`,
        cancelConfirmationThirdTitle: `Tu suscripción seguirá activa por el periodo pagado`,

        //Accept Terms
        acceptTermsTitle: `Confirma tu plan`,
        acceptTermsSubTitle: `Tu suscripción mensual de <product> costará <price>`,

        //Purchase Confirmation
        purchaseConfirmationTitle: `¡Bienvenido a Paramount+!​`,
        purchaseConfirmationSubTitle: `Gracias por suscribirte.​ Recibirás un mensaje de texto ​para activar tu suscripción.​`,

        //General Failure
        generalFailureTitle: `¡Disculpa!​`,
        generalFailureSubTitle: `Hubo un problema.​`,

        //Authentication Failure
        authenticationFailureTitle: `¡Disculpa!​`,
        authenticationFailureSubTitle: `Tuvimos una falla con tu solicitud.​`,

        //General
        purchase: `Suscríbete​`,
        manageSubscription: `Administra tu suscripción​`,
        loading: 'Loading',
        selectProduct: `Selecciona tu plan`,
        acceptTerms: `Acepto los Términos y Condiciones`,
        okay: `Confirmar​`,
        confirm: `Confirmar​`,
        cancel: `Cancelar​`,
        change: `Cambiar`,
        activate: `Activar​`,
        cancelSubscription: `Cancela tu suscripción​`,
        changeSubscription: `Cambia tu suscripción​`,
        currentSubscription: `Suscripción actual​`,
        goBack: `Regresar`,
        continue: `Continuar`,
        useAnotherGoogleAccount: `Usar otra cuenta Google​`,
        cancelOtherSubscription: `Cancelar otra suscripción​`,
        subscriptionActivated: `Suscripción activada`,
        serviceWillRemainActive: `Tu suscripción seguirá activa hasta el​`,
        activateSubscription: `Activa tu suscripción`,
        confirmSubscription: `Confirmar​ tu suscripción`,
        tryAgain: `Intentar de nuevo`,
        customerWillNotBeCharged: `Customer will not be charged`,
        subscriptionCancelled: `Suscripción Cancelada​`,
        backToHome: `Back to Home`,
        newSubscription: `New Subscription`,
        selectNewSubscription: `Elige tu nuevo plan​`,
        modify: `Modifica tu plan`,
        continue: 'Continuar',

        //Errors
        fieldRequired: `Este es un campo obligatorio.`,
    }
}

export default { GOOGLE, HBO, PRM };