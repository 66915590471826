import axios from 'axios';
import _ from 'lodash';

const { REACT_APP_M1_TOKENS_API_URL, REACT_APP_API_URL } = process.env;


export const apiUlm = {    
    getUserAccount: (async () => {
        const tokenString = localStorage.getItem('token_data') | {};
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'get',
            baseURL: REACT_APP_M1_TOKENS_API_URL,
            url: 'user/accounts',
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            },
        };
        
        return await axios( request );
    }),
    getPartnerSubscriptions: (async (payload) => {
        const tokenString = localStorage.getItem('token_data') | {};
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/partnerSubscriptions',
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            },
            data: {
                subscriptionId: _.get(payload, 'subscriptionId'),
                accountId: _.get(payload, 'accountId'),
            }
        };
        
        return await axios( request );
    }),
    
    getUserSubscriptions: (async (payload) => {
        const tokenString = localStorage.getItem('token_data') | {};
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/userSubscriptions',
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            },
            data: {
                entityFieldValues: _.get(payload, 'entityFieldValues'),
                offset: _.get(payload, 'offset'),
                size: _.get(payload, 'size'),
            }
        };
        
        return await axios( request );
    }),

    getUserAccounts: (async (payload) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);

        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/userAccounts',
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            },
            data: {
                entityFieldValues: _.get(payload, 'entityFieldValues'),
                offset: _.get(payload, 'offset'),
                size: _.get(payload, 'size'),
            }
        };
        
        return await axios( request );
    }),
    getPaymentMethods: (async () => {
        const tokenString = localStorage.getItem('token_data') | {};
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'get',
            baseURL: REACT_APP_M1_TOKENS_API_URL,
            url: 'user/paymentMethod',
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            },
        };
        
        return await axios( request );
    }),
    endSession: (async () => {        
        const request = {
            method: 'post',
            baseURL: REACT_APP_M1_TOKENS_API_URL,
            url: 'session/end'
        };
        
        return await axios( request );
    }),
    startSession: (async () => {        
        const request = {
            method: 'post',
            baseURL: REACT_APP_M1_TOKENS_API_URL,
            url: 'session/start'
        };
        
        return await axios( request );
    }),
    levelSession: (async () => {        
        const request = {
            method: 'get',
            baseURL: REACT_APP_M1_TOKENS_API_URL,
            url: 'session/level'
        };
        
        return await axios( request );
    }),
}