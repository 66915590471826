import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { Flex, CheckboxField, Link } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import Fonts from "../../../constants/Fonts";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import TextButton from "../../../components/Buttons/TextButton";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import Spinner from "../../../components/Spinners/primary";
import useToken from "../../../hooks/useToken";
import useMarket from "../../../hooks/useMarket";

export default function AcceptTerms(props) {

    const navigate = useNavigate();
    const { height } = useDeviceTypes();
    const { getTokenData } = useToken();
    const { fetchProduct, ulmFlow, getToken, setProduct, product, productId, subscriptionValidations, createSubscription, getCurrentPartnerSub } = useMarket();

    const params = new URLSearchParams(window.location.search);
    //const [productId] = useState(getProductId());
    //const [productId] = useState(params.get("x-ulm-product_id"));
    const [code] = useState(params.get("code"));

    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [error, setError] = useState(false);
    const [error2, setError2] = useState(false);
    const [trial, setTrial] = useState(false);

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].acceptTermsTitle;
    }, []);    

    useEffect(() => {
        (async() => {
            //If no token stored, redirect to ulm
            if(!getTokenData()?.access_token && !code) await ulmFlow({ redirectUri: ScreenNames.GOOGLE.AcceptTerms, productId: productId });

            //If no token or code, get token data
            if(!getTokenData()?.access_token && code) await getToken(code, ScreenNames.GOOGLE.AcceptTerms, '', productId);
                        
            //if not logged in, redirect to auth failure
            // if(!getTokenData()?.access_token) navigate(ScreenNames.GOOGLE.AuthenticationFailure);
            if(!getTokenData()?.access_token) await ulmFlow({ redirectUri: ScreenNames.GOOGLE.AcceptTerms, productId: productId });

            const { partnerSubscription } = await getCurrentPartnerSub(true);
            // console.log({partnerSubscription})

            //If subscribed but still inactive redirect to purchase confirmation
            if((partnerSubscription?.serviceStatus === 'UNPROVISIONED')) return navigate(ScreenNames.GOOGLE.PurchaseConfirmation);
            if((partnerSubscription?.serviceStatus === 'INACTIVE') || (partnerSubscription?.serviceStatus === 'PAUSE-IN-PROGRESS') || (partnerSubscription?.serviceStatus === 'CANCELLATION-IN-PROGRESS') || (partnerSubscription?.serviceStatus === 'ACTIVE')) return navigate(ScreenNames.GOOGLE.ManageSubscription);


            //Add a function here to prevent purchase multiple products of them same when ACTIVE

            // Get product
            const productResult = await fetchProduct(productId);
            setProduct(productResult);

            //Only GOOGLE1_100GB is eligible for a free trial
            let subscriptionValidation = trial;
            if(productId === 'GOOGLE1_100GB') {
                subscriptionValidation = await subscriptionValidations(productId);
                setTrial(subscriptionValidation);
            }

            // console.log({subscriptionValidation})

            //Dry run subscription
            // const dryRunSubscription = await createSubscription( { productId, dryRun: true, trial: subscriptionValidation } );
            // console.log({dryRunSubscription});
            setLoading(false);

        })();
    }, []);

    const next = async() => {

        if(!checked) {
            setError(true);
            return false;
        }
        if(!checked2) {
            setError2(true);
            return false;
        }

        setError(false);
        setError2(false);

        setApiLoading(true);

        const res = await createSubscription( { productId, dryRun: false, trial } );
        // console.log({res})
        setApiLoading(false);

        navigate(ScreenNames.GOOGLE.PurchaseConfirmation);
    }

    return (
        <Container minHeight={'90vh'}>
            { !loading ? <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].acceptTermsTitle} subtitle={Strings.GOOGLE[Language].acceptTermsSubTitle.replace('<product>', product.descriptions.data[0].description).replace('<price>', '$' + product.prices.data[0].amount)}/>
                {/* <Flex direction={'column'} marginTop={225} width={isMobileTablet ? width * 0.9 : 500}>
                    <ScrollView paddingRight={20} paddingLeft={20} height={300} backgroundColor='white' border={'black'} direction='column' >
                        <h4>Terms & Conditions</h4>
                        <p style={{ fontSize: '13px', marginTop: -10}}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vestibulum lectus mauris ultrices eros in cursus. Turpis massa tincidunt dui ut. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit. Viverra nibh cras pulvinar mattis. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo integer. Accumsan tortor posuere ac ut consequat semper viverra nam. Arcu non sodales neque sodales ut etiam sit amet nisl. Fames ac turpis egestas integer. Proin fermentum leo vel orci porta non pulvinar neque laoreet.

                            In tellus integer feugiat scelerisque varius morbi. Cras adipiscing enim eu turpis. In nisl nisi scelerisque eu ultrices. In mollis nunc sed id semper risus in. A cras semper auctor neque vitae tempus quam pellentesque nec. Ultrices tincidunt arcu non sodales neque sodales ut. Turpis egestas sed tempus urna. Tempus imperdiet nulla malesuada pellentesque elit eget gravida. Turpis egestas maecenas pharetra convallis. Amet facilisis magna etiam tempor orci eu lobortis elementum nibh. Nulla facilisi cras fermentum odio eu feugiat pretium nibh ip
                        </p>
                    </ScrollView>
                    <CheckboxField 
                    errorMessage={Strings.GOOGLE[Language].fieldRequired} 
                    hasError={error}
                    fontFamily={Fonts["ATTAleckSans-Rg"]} 
                    label={Strings.GOOGLE[Language].acceptTerms} 
                    checked={checked} 
                    value={checked} 
                    onChange={(e) => setChecked(e.target.checked)} />
                </Flex> */}
                <Flex direction={'column'} marginTop={50} maxWidth={500} alignSelf='center'>
                    <Flex direction={'column'}>
                        <CheckboxField 
                            errorMessage={Strings.GOOGLE[Language].fieldRequired} 
                            hasError={error}
                            marginBottom={45}
                            fontFamily={Fonts["ATTAleckSans-Rg"]}
                            checked={checked}
                            label={<Link marginLeft={15} target={'_blank'} href="https://www.att.com.mx/entretenimiento">{Strings.GOOGLE[Language].acceptTerms}</Link>} 
                            value={checked} 
                            onChange={(e) => setChecked(e.target.checked)} />
                        <CheckboxField 
                            errorMessage={Strings.GOOGLE[Language].fieldRequired} 
                            hasError={error2}
                            marginBottom={45}
                            fontFamily={Fonts["ATTAleckSans-Rg"]}
                            checked={checked2} 
                            value={checked2} 
                            label={<Link marginLeft={15} target={'_blank'} href="https://one.google.com/terms-of-service">{Strings.GOOGLE[Language].acceptG1Terms}</Link>}
                            onChange={(e) => setChecked2(e.target.checked)} />
                    </Flex>
                    <OutlineButton
                        disabled={apiLoading}
                        isLoading={apiLoading}
                        children={Strings.GOOGLE[Language].confirm}
                        onClick={next}
                        />
                    <TextButton                        
                        marginTop={20}
                        children={Strings.GOOGLE[Language].modify}
                        onClick={() => navigate(ScreenNames.GOOGLE.SelectProduct)}
                        />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex> }
        </Container>
    )
}