import { useState } from 'react';
// import { apiAuth } from '../services';

//TOKEN DATA SCHEMA
// {
//     "access_token": "tokenValue",
//     "expires_in": 3599,
//     "id_token": "tokenValue",
//     "refresh_token": "tokenValue",
//     "scope": "openid offline_access profile",
//     "token_type": "Bearer"
// }

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token') | {};
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const getRefreshToken = () => {
        const tokenString = localStorage.getItem('refresh_token') | {};
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const getTokenData = () => {
        const tokenString = localStorage.getItem('token_data');
        const userToken = JSON.parse(tokenString);
        return userToken || {};
    };

    const getGoogleTokenData = () => {
        const tokenString = localStorage.getItem('google_token_data');
        const userToken = JSON.parse(tokenString);
        return userToken || {};
    };

    const [token, setToken] = useState(getToken());
    const [refreshToken, setRefreshToken] = useState(getRefreshToken());
    const [tokenData, setTokenData] = useState(getTokenData());
    const [googleTokenData, setGoogleTokenData] = useState(getGoogleTokenData());

    const saveToken = token => {
        localStorage.setItem('token', JSON.stringify(token));
        setToken(token);
    };
    const saveRefreshToken = token => {
        localStorage.setItem('refresh_token', JSON.stringify(token));
        setRefreshToken(token);
    };
    const saveTokenData = token => {
        localStorage.setItem('token_data', JSON.stringify(token));
        setTokenData(token);
    };
    const saveGoogleTokenData = token => {
        localStorage.setItem('google_token_data', JSON.stringify(token));
        setGoogleTokenData(token);
    };

    return {
        setToken: saveToken,
        setRefreshToken: saveRefreshToken,
        setTokenData: saveTokenData,
        setGoogleTokenData: saveGoogleTokenData,
        getTokenData,
        getGoogleTokenData,
        token,
        refreshToken,
        googleTokenData,
        tokenData,

    }
}
