import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { View, Text } from "@aws-amplify/ui-react";
import Strings from "../../constants/Strings";
import Language from "../../utils/Language";
import TextDimensions from "../../constants/TextDimensions";
import Fonts from '../../constants/Fonts';

export default function SubTitle(props) {
    useEffect(() => {}, []);

    return (
        <Text textAlign='center' fontFamily={Fonts["ATTAleckSans-Rg"]} fontSize={TextDimensions.small} {...props} />
    )
}