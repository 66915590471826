const HBO_URL = '/max';
const GOOGLE_URL = '/google1';
const PRM_URL = '/paramountplus';

const General = {
    Home: HBO_URL,
}

const HBO = {    
    Home: `${HBO_URL}`,
    Request: `${HBO_URL}/respuesta`,
}
const PRM = {    
    Home: `${PRM_URL}`,
    ManageSubscription: `${PRM_URL}/manage-subscriptions`,
    CancelSubscription: `${PRM_URL}/cancel-subscription`,
    Purchase: `${PRM_URL}/purchase`,
    CancelConfirmation: `${PRM_URL}/cancel-confirmation`,
    AcceptTerms: `${PRM_URL}/pre-purchase`,
    PurchaseConfirmation: `${PRM_URL}/purchase-confirmation`,
    GeneralFailure: `${PRM_URL}/failure`,
    AuthenticationFailure: `${PRM_URL}/auth-failure`,

}

const GOOGLE = {
    Store: GOOGLE_URL,
    //Store: process.env.REACT_APP_LOCAL ? GOOGLE_URL : '',
    SelectProduct: `${GOOGLE_URL}/select-product`,
    AcceptTerms: `${GOOGLE_URL}/pre-purchase`,
    PurchaseConfirmation: `${GOOGLE_URL}/purchase-confirmation`,
    GeneralFailure: `${GOOGLE_URL}/failure`,
    ManageSubscription: `${GOOGLE_URL}/manage-subscriptions`,
    CancelSubscription: `${GOOGLE_URL}/cancel-subscription`,
    CancelConfirmation: `${GOOGLE_URL}/cancel-confirmation`,
    CancelConfirmationOther: `${GOOGLE_URL}/cancel-confirmation-other`,
    Authentication: `${GOOGLE_URL}/auth`,
    AuthenticationFailure: `${GOOGLE_URL}/auth-failure`,
    Purchase: `${GOOGLE_URL}/purchase`,
    ChangeSubscription: `${GOOGLE_URL}/change-subscription`,
    ChangeSubscriptionConfirmation: `${GOOGLE_URL}/change-confirmation`,
    ActivateSubscription: `${GOOGLE_URL}/activate-subscription`,
    LoginWithGoogle: `${GOOGLE_URL}/google-login`,
    GoogleError: `${GOOGLE_URL}/google-error`,
    ActivationConfirmation: `${GOOGLE_URL}/activation-confirmation`,
    ConfirmCancellation: `${GOOGLE_URL}confirm-cancellation`,
    Request: `${GOOGLE_URL}/requesta`,
    Error: `${GOOGLE_URL}/error`,
    Error404: `${GOOGLE_URL}/404`,
}

export default { General, HBO, GOOGLE, PRM };