import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import useMarket from "../../../hooks/useMarket";
import Spinner from "../../../components/Spinners/primary";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import useToken from "../../../hooks/useToken";

export default function Store(props) {

    const navigate = useNavigate();
    const { ulmFlow } = useMarket();
    const { height } = useDeviceTypes();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].googleOne;
    }, []);
    
    useEffect(() => {
        (async() => {            
            setLoading(false);
        })();
    }, []);

    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].googleOne} subtitle={``}/>
                <Flex direction={'column'} marginTop={100}>
                    <PrimaryButton
                        children={Strings.GOOGLE[Language].purchase}
                        onClick={() => navigate(ScreenNames.GOOGLE.SelectProduct)}
                        />
                    <PrimaryButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].manageSubscription}
                        onClick={() => ulmFlow({})}
                        />
                    <PrimaryButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].activate}
                        onClick={() => ulmFlow({})}
                        />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex> }
        </Container>
    )
}