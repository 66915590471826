import React from 'react';
import ReactDOM from 'react-dom';
import { AmplifyProvider } from '@aws-amplify/ui-react';

import App from './App';
import Fonts from './constants/Fonts';
import Colors from './constants/Colors'
import reportWebVitals from './reportWebVitals';

import '@aws-amplify/ui-react/styles.css';
import './index.css';


//import 'react-toastify/dist/ReactToastify.css';
const theme = {
  name: 'my-theme',
  tokens: {
    colors: {
      border: {
        primary: { value: Colors.primary }
      },
    },
    fonts: {
      default: {
        variable: { value: Fonts['ATTAleckSans-Rg'] },
        static: { value: Fonts['ATTAleckSans-Rg'] },
      },
    },
    components: {
      text: {
        color: { value: Colors.black }
      },
      tabs: {
        item: {
          active: {
            color: { value: Colors.primary },
            border: {
              color: { value: Colors.secondary }
            },
          },
          color:{ value: Colors.secondary },
          focus: {
            color:{ value: Colors.primary }
          },
          //color:{ value: Colors.secondary }
        }
      },
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: '{fontWeights.black.value}' },
        // style the primary variation
        primary: {
          backgroundColor: { value: Colors.primary },
          _hover: {
            backgroundColor: { value: Colors.secondary },
          },
          _focus: {
            backgroundColor: { value: Colors.secondary },
          },
        },
      },
      fieldcontrol: {
        _focus: {
          borderColor: { value: Colors.primary }
        }
      },
      radio: {
        button: {
          _checked: {
            color: { value: Colors.primary },
          },
        },
      },
      
    },
  },
};

ReactDOM.render(
  <React.StrictMode>    
    <AmplifyProvider theme={theme}>
      <App />    
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
