import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { Flex, Text } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import GoogleSignInButton from "../../../components/Buttons/GoogleButton";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";
import Colors from "../../../constants/Colors";
import useMarket from "../../../hooks/useMarket";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import Spinner from "../../../components/Spinners/primary";
import useToken from "../../../hooks/useToken";

export default function ActivateSubscription(props) {

    const { height } = useDeviceTypes();

    const { getCurrentPartnerSub, googleAuth, activateGoogle, googleAuthentication } = useMarket();

    const { googleTokenData } = useToken();

    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    
    const [subscription, setSubscription] = useState({});

    const [product, setProduct] = useState({});

    const params = new URLSearchParams(window.location.search);
    const [code] = useState(params.get("code"));
    const [error] = useState(params.get("error"));

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].activateSubscriptionTitle;
    }, []);

    // useEffect(() => {
    //     if(error) 
    // }, [error]);

    useEffect(() => {
        (async() => {
            // console.log({code})
            // console.log({subscription})
            if(code && subscription?.partnerSubscriptionId) {
                setApiLoading(true);
                await googleAuthentication(code);
                await activateGoogle(subscription.partnerSubscriptionId);
                setApiLoading(false);
            }
        })();
    }, [code, subscription]);

    useEffect(() => {
        (async() => {

            //Get partner subs
            const { productData, partnerSubscription } = await getCurrentPartnerSub();
            setProduct(productData);
            setSubscription(partnerSubscription); 
            
            setLoading(false);
            
            
        })();
    }, []);

    const next = async() => {
        setApiLoading(true);
        await googleAuth();
        setApiLoading(false);

    }

    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].activateSubscriptionTitle} subtitle={Strings.GOOGLE[Language].activateSubscriptionSubTitle}/>
                <Flex alignItems={'center'} direction={'column'} marginTop={25} height={200} justifyContent='center'>                    
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small}>
                        {Strings.GOOGLE[Language].activateSubscription}
                    </Text>
                    <Text fontFamily={Fonts.body} color={Colors.primary} fontSize={TextDimensions.small}>
                        {product?.descriptions?.data[0]?.description}
                    </Text>
                    
                </Flex>
                <Flex direction={'column'} marginTop={50} width={350} alignSelf='center'>
                    <Text fontFamily={Fonts["ATTAleckCd-Bold"]} color={Colors.primary} fontSize={TextDimensions.small} marginBottom={15} textAlign='center'>
                        {Strings.GOOGLE[Language].activationConfirmationThirdTitle}
                    </Text>
                    {/* <OutlineButton
                        disabled={apiLoading}
                        isLoading={apiLoading}
                        children={Strings.GOOGLE[Language].confirm}
                        onClick={next}
                        /> */}
                    <GoogleSignInButton
                    style={{ alignSelf: 'center '}}
                    disabled={apiLoading}
                    isLoading={apiLoading}
                    onClick={() => next()} />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex>} 
        </Container>
    )
}