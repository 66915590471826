import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import useToken from "../../../hooks/useToken";
import Spinner from "../../../components/Spinners/primary";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import useMarket from "../../../hooks/useMarket";

export default function ManageSubscription(props) {

    const navigate = useNavigate();
    const { height } = useDeviceTypes();    

    const { getTokenData } = useToken();

    const { ulmFlow, getToken, getCurrentPartnerSub } = useMarket();

    const params = new URLSearchParams(window.location.search);
    const [code] = useState(params.get("code"));

    const [loading, setLoading] = useState(true);

    const [status, setStatus] = useState('ACTIVE'); //Activating, Activated, Suspended, Deactivated, Pending, Other

    const [subscription, setSubscription] = useState({});

    const [product, setProduct] = useState({});
    
    useEffect(() => {
        document.title = Strings.GOOGLE[Language].manageSubscriptionTitle;
    }, []);

    
    useEffect(() => {
        (async() => {            

            //If no token stored, redirect to ulm
            if(!getTokenData()?.access_token && !code) navigate(ScreenNames.GOOGLE.AuthenticationFailure);

            //If no token or code, get token data
            if(!getTokenData()?.access_token && code) await getToken(code);

            //Get user
            // await getUserInfo();

            //Get partner subs
            const { partnerSubscription, productData } = await getCurrentPartnerSub();

            setProduct(productData);

            setSubscription(partnerSubscription);
            
            setStatus(partnerSubscription.serviceStatus);
            
            setLoading(false);
            
        })();
    }, []);

    useEffect(() => {
        if((status === 'CANCELLED')) navigate(ScreenNames.GOOGLE.Purchase);
    }, [status]);


    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].manageSubscriptionTitle} subtitle={''}/>
                <Flex direction={'column'} marginTop={300}>
                    {status === 'INACTIVE' && <PrimaryButton
                        children={Strings.GOOGLE[Language].activateSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.ActivateSubscription)}
                        />}
                    {status === 'UNPROVISIONED' && <PrimaryButton
                        children={Strings.GOOGLE[Language].activateSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.ActivateSubscription)}
                        />}
                    {status === 'UNPROVISIONED' && <PrimaryButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { state: { subscription, product } } )}
                        />}
                    {status === 'INACTIVE' && <PrimaryButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { state: { subscription, product } } )}
                        />}
                    {status === 'ACTIVE' && <PrimaryButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { state: { subscription, product } } )}
                        />}
                    {status === 'CANCELLATION-IN-PROGRESS' && <PrimaryButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { state: { subscription, product } } )}
                        />}
                    {/* {status === 'ACTIVE' && <OutlineButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].changeSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.ChangeSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />}
                    {status === 'INACTIVE' && <OutlineButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].changeSubscription}
                        onClick={() => navigate(ScreenNames.GOOGLE.ChangeSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />} */}
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex>} 
        </Container>
    )
}