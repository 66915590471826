import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";

export default function PRM_GeneralFailure(props) {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = Strings.PRM[Language].generalFailureTitle;
    }, []);

    useEffect(() => {}, []);

    const next = () => {

        navigate(ScreenNames.PRM.Home);
    }
    
    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={Strings.PRM[Language].generalFailureTitle} subtitle={Strings.PRM[Language].generalFailureSubTitle }/>
                
                <Flex direction={'column'} marginTop={150} width={350} alignSelf='center'>
                    <OutlineButton
                        children={Strings.PRM[Language].tryAgain}
                        onClick={next}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}