import axios from 'axios';
import _ from 'lodash';

const { REACT_APP_API_URL } = process.env;

export const apiSubscriptions = {
    create: (async ( payload ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: 'subscriptions',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            }
        };

        return await axios( request );
    }),
    cancel: (async ( { id, dryRun, disentitle } ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: `subscriptions/${id}/cancel`,
            data: {
                dryRun,
                disentitle,
            },
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            }
        };

        return await axios( request );
    }),
    getPartnerSubscriptions: (async (payload) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/partnerSubscriptionsV3',
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            },
            data: {
                accountId: _.get(payload, 'accountId'),
            }
        };
        
        return await axios( request );
    }),
    activateGoogleOne: (async (id) => {
        const tokenString = localStorage.getItem('google_token_data');
        const tokenData = JSON.parse(tokenString);
        const tokenStringM1 = localStorage.getItem('token_data');
        const userTokenData = JSON.parse(tokenStringM1);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: `/subscriptions/google/${id}/activate`,
            headers: {
                'Authorization': 'Bearer ' + _.get(userTokenData, 'access_token') 
            },
            data: {
                accessToken: _.get(tokenData, 'access_token'),
                refreshToken: _.get(tokenData, 'refresh_token'),
            }
        };
        
        return await axios( request );
    }),
    getParamountRedemptionCode: (async ( payload ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: `/paramount/activate/redemption`,
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            },
            data: { }
        };
        
        return await axios( request );
    }),

}