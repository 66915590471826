import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";

export default function PRM_Error404(props) {

    const navigate = useNavigate();    

    useEffect(() => {
        document.title = `404`;
    }, []);
    useEffect(() => {}, []);

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={`404`} subtitle={``}/>
                <Flex direction={'column'} marginTop={300}>
                    <PrimaryButton
                        children={Strings.PRM[Language].backToHome}
                        onClick={() => navigate(ScreenNames.PRM.Home)}
                        />
                </Flex>
                
            </Flex> 
        </Container>
    )
}