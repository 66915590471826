import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';

const baseURL = 'https://35iw8sgrl4.execute-api.us-east-2.amazonaws.com/dev/api';
const redirectUri = 'https://dev.att-mx-contentshop.com';
const clientId = '77dcb95023f84b6383ffbc3061c81f49';
// const amdocsURL = 'https://att-mx-ulm-pdt.m1amdocs.io';

const { REACT_APP_M1_TOKENS_BASE_URL, REACT_APP_GOOGLE_AUTH_CLIENT_ID, REACT_APP_API_URL } = process.env;


export const apiAuth = {
    getToken: (async ( payload ) => {
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/token',
            data: {
                authCode: _.get(payload, 'authCode'),
                redirectUri: _.get(payload, 'redirectUri', redirectUri),
                clientId: _.get(payload, 'clientId', clientId),
                grant_type: 'authorization_code',
                app: _.get(payload, 'app', 'GOOGLE'),
            }
        };
        
        return await axios( request );
    }),
    revokeToken: (async ( payload ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/token/revoke',
            data: {
                token: _.get(tokenData, 'access_token'),
                app: _.get(payload, 'app', 'GOOGLE1'),
            }
        };
        
        return await axios( request );
    }),
    refreshToken: (async ( payload ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/token/refresh',
            data: {
                accessToken: _.get(tokenData, 'access_token'),
                refreshToken: _.get(tokenData, 'refresh_token'),
                app: _.get(payload, 'app', 'GOOGLE1'),
            }
        };
        
        return await axios( request );
    }),
    getUserInfo: (async () => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'get',
            baseURL: REACT_APP_API_URL,
            url: '/userInfo',
            headers: { 
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            }
        };
        
        return await axios( request );
    }),
    getUser: (async () => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'get',
            baseURL: REACT_APP_API_URL,
            url: '/user',
            headers: { 
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            }
        };
        
        return await axios( request );
    }),    
    authorize: (async (payload) => {
        const request = {
            client_id: _.get(payload, 'clientId', clientId),
            redirect_uri: _.get(payload, 'redirectUri', redirectUri),
            response_type: 'code',
            scopes: 'phone openid storefront profile',
            state: 999,
            // 'x-ulm-product_id': _.get(payload, 'productId', ''),
            'x-ulm-productId': _.get(payload, 'productId', ''),
        };

        const query = qs.stringify(request, {
            encode: false
        });


        return `${REACT_APP_M1_TOKENS_BASE_URL}/oidc/authorize?${query}`;
        
    }),
    googleAuth: (async (payload) => {
        const request = {
            client_id: REACT_APP_GOOGLE_AUTH_CLIENT_ID,
            redirect_uri: _.get(payload, 'redirectUri'),
            scope: 'https://www.googleapis.com/auth/subscriptions.thirdparty.googleone',
            response_type: 'code',
            include_granted_scopes: true,
            // state: 'pass-through value',
            access_type: 'offline'
        };
        const query = qs.stringify(request, {
            encode: false
        });
        return `https://accounts.google.com/o/oauth2/v2/auth?${query}`;
    }),
    googleAuthTokens: (async ( payload ) => {
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/googleAuthTokens',
            data: {
                code: _.get(payload, 'code'),
                redirectUri: _.get(payload, 'redirectUri'),
            }
        };
        
        return await axios( request );
    }),
    googleAuthRevoke: (async () => {
        const tokenString = localStorage.getItem('google_token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/googleAuthRevoke',
            data: {
                token: _.get(tokenData, 'access_token'),
            }
        };
        
        return await axios( request );
    }),
    googleAuthRefresh: (async () => {
        const tokenString = localStorage.getItem('google_token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'post',
            baseURL: REACT_APP_API_URL,
            url: '/googleAuthRefresh',
            data: {
                token: _.get(tokenData, 'refresh_token'),
            }
        };
        
        return await axios( request );
    }),
}