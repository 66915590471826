import axios from 'axios';
import _ from 'lodash';

const baseURL = _.get(process.env, 'REACT_APP_API_URL');

export const apiProducts = {
    list: (async ( payload ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/products',
            params: payload,
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            }
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/products/' + id,
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            }
        };
        
        return await axios( request );
    }),
}