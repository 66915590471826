import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from 'moment-timezone';
import { useNavigate } from "react-router-dom";
import { Flex, CheckboxField, Link } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import Fonts from "../../../constants/Fonts";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import TextButton from "../../../components/Buttons/TextButton";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import Spinner from "../../../components/Spinners/primary";
import useToken from "../../../hooks/useToken";
import useMarket from "../../../hooks/useMarket";

const { 
    REACT_APP_PRM_SCHEDULED_PROMO_START,
    REACT_APP_PRM_SCHEDULED_PROMO_END,
    REACT_APP_PRM_SCHEDULED_PROMO_CODE,
    REACT_APP_PRM_SCHEDULED_PROMO_TEXT,
    REACT_APP_PRM_SCHEDULED_PROMO_ALLOW_TRIAL,
    REACT_APP_PRM_SCHEDULED_DISABLE_FREE_TRIALS_AFTER,
    REACT_APP_TIMEZONE
} = process.env;

export default function PRM_AcceptTerms(props) {

    const navigate = useNavigate();
    const { height } = useDeviceTypes();
    const { getTokenData } = useToken();
    const { fetchProduct, ulmFlow, getToken, product, setProduct, subscriptionValidations, createSubscription, getCurrentPartnerSub, openAttWindow, fetchProducts, trackingId, setTrackingId } = useMarket();

    const params = new URLSearchParams(window.location.search);
    const [productId, setProductId] = useState(process.env.REACT_APP_PRODUCT_ID || 'paramountpluslatam');
    //const [productId] = useState(params.get("x-ulm-productId"));
    const [code] = useState(params.get("code"));

    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState(false);
    const [trial, setTrial] = useState(false);
    const [promoActive, setUsePromo] = useState(false);

    useEffect(() => {
        document.title = Strings.PRM[Language].acceptTermsTitle;
    }, []);
    
    // Check if promo should be applied
    useEffect(() => {
        const start = moment(REACT_APP_PRM_SCHEDULED_PROMO_START, 'YYYY-MM-DD HH:mm:ss').tz(REACT_APP_TIMEZONE);
        const end = moment(REACT_APP_PRM_SCHEDULED_PROMO_END, 'YYYY-MM-DD HH:mm:ss').tz(REACT_APP_TIMEZONE);
        const now = moment().tz(REACT_APP_TIMEZONE);
        
        if(now.isBetween(start,end)) {
            setUsePromo(true);
        }
    }, []); 

    useEffect(() => {
        (async() => {
            //If no token stored, redirect to ulm
            if(!getTokenData()?.access_token && !code) await ulmFlow({ redirectUri: ScreenNames.PRM.AcceptTerms, productId: productId, appName: 'PRM' });

            //If no token or code, get token data
            if(!getTokenData()?.access_token && code) await getToken(code, ScreenNames.PRM.AcceptTerms, 'PRM');

            //if not logged in, redirect to auth failure
            if(!getTokenData()?.access_token) await ulmFlow({ redirectUri: ScreenNames.PRM.AcceptTerms, productId: productId, appName: 'PRM' });

            const { partnerSubscription } = await getCurrentPartnerSub(true, 'PRM');

            //fetch products
            const products = await fetchProducts('PRM');
            if(products[0]?.id) setProductId(products[0]?.id);            

            //If subscribed but still inactive redirect to purchase confirmation
            if((partnerSubscription?.serviceStatus === 'UNPROVISIONED')) return navigate(ScreenNames.PRM.PurchaseConfirmation);
            if((partnerSubscription?.serviceStatus === 'INACTIVE') || (partnerSubscription?.serviceStatus === 'PAUSE-IN-PROGRESS') || (partnerSubscription?.serviceStatus === 'CANCELLATION-IN-PROGRESS') || (partnerSubscription?.serviceStatus === 'ACTIVE')) return navigate(ScreenNames.PRM.ManageSubscription);

            // //Add a function here to prevent purchase multiple products of them same when ACTIVE

            // Get product
            const productResult = await fetchProduct(products[0]?.id, 'PRM');
            setProduct(productResult);

            // Determine if a free trial is eligible based on the product and subscription status
            // let subscriptionValidation = trial;

            // Check if the product is 'paramountpluslatam'
            if (productId === 'paramountpluslatam') {
                // Validate the subscription for PRM
                const subscriptionValidation = await subscriptionValidations(productId, 'PRM');
            
                // Check if the conditions for not allowing a trial are met
                if (!subscriptionValidation && promoActive && (REACT_APP_PRM_SCHEDULED_PROMO_ALLOW_TRIAL === 'false')) {
                    // Set trial to false if conditions are met
                    setTrial(false);
                } else if (subscriptionValidation){
                    // Set trial to true if subscriptionValidation is true
                    if(promoActive && (REACT_APP_PRM_SCHEDULED_PROMO_ALLOW_TRIAL === 'false')) setTrial(false);
                    else setTrial(true);
                } else {
                    // Set trial to false for other cases where subscriptionValidation is false
                    setTrial(false);
                }
            }
  


            setLoading(false);

        })();
    }, []);

    const next = async() => {

        if(!checked) {
            setError(true);
            return false;
        }

        setError(false);

        setApiLoading(true);

        let _trial = trial;

        if(promoActive && (REACT_APP_PRM_SCHEDULED_PROMO_ALLOW_TRIAL === 'false')) _trial = false;

        // console.log('promoActive', promoActive);
        // console.log('PROMO_ALLOW_TRIAL', REACT_APP_PRM_SCHEDULED_PROMO_ALLOW_TRIAL);

        let payload = { productId, dryRun: false, trial: _trial, appName: 'PRM', trackingId };
        if (promoActive) payload.campaignCode = REACT_APP_PRM_SCHEDULED_PROMO_CODE;

        const res = await createSubscription(payload);
        setTrackingId('');
        setApiLoading(false);

        if(!res) navigate(ScreenNames.PRM.GeneralFailure)
        else navigate(ScreenNames.PRM.PurchaseConfirmation);
    }

    return (
        <Container minHeight={'90vh'}>
            { !loading ? <Flex direction={'column'}>
                <Header
                title={Strings.PRM[Language].acceptTermsTitle}
                subtitle={
                    promoActive ?
                    REACT_APP_PRM_SCHEDULED_PROMO_TEXT :
                    Strings.PRM[Language].acceptTermsSubTitle.replace('<product>', 'Paramount+').replace('<price>', '$' + product.prices.data[0].amount)
                }
                />
                
                <Flex direction={'column'} marginTop={50} width={350} alignSelf='center'>
                    <Flex direction={'row'} justifyContent={'center'} >
                        <CheckboxField 
                            errorMessage={Strings.PRM[Language].fieldRequired} 
                            hasError={error}
                            marginBottom={45}
                            alignSelf={'center'}
                            fontFamily={Fonts["ATTAleckSans-Rg"]}
                            checked={checked} 
                            value={checked} 
                            onChange={(e) => setChecked(e.target.checked)} />
                        <Link target={'_blank'} href="https://www.att.com.mx/entretenimiento">{Strings.PRM[Language].acceptTerms}</Link>
                    </Flex>
                    <OutlineButton
                        disabled={apiLoading}
                        isLoading={apiLoading}
                        children={Strings.PRM[Language].confirm}
                        onClick={next}
                        />
                    <TextButton                        
                        marginTop={20}
                        children={Strings.PRM[Language].cancel}
                        onClick={() => navigate(ScreenNames.PRM.Home)}
                        />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex> }
        </Container>
    )
}