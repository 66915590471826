import React, { useEffect, useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import useMarket from "../../../hooks/useMarket";
import Spinner from "../../../components/Spinners/primary";
import useDeviceTypes from "../../../hooks/useDeviceTypes";

export default function ParamountLandingPage(props) {

    const { ulmFlow, setTrackingId } = useMarket();
    const { height } = useDeviceTypes();
    const [loading, setLoading] = useState(true);

    const params = new URLSearchParams(window.location.search);
    const [productId, setProductId] = useState(process.env.REACT_APP_PRODUCT_ID || 'paramountpluslatam');
    

    useEffect(() => {
        document.title = Strings.PRM[Language].landingTitle;
    }, []);
    
    useEffect(() => {
        (async() => {
            setTrackingId(params.get("trackingID"));            
            setLoading(false);
        })();
    }, []);

    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.PRM[Language].landingTitle} subtitle={``}/>
                <Flex direction={'column'} marginTop={150}>
                    <PrimaryButton
                        children={Strings.PRM[Language].purchase}
                        onClick={() => ulmFlow({ redirectUri: ScreenNames.PRM.AcceptTerms, productId, appName: 'PRM' })}
                        />
                    <PrimaryButton
                        marginTop={20}
                        children={Strings.PRM[Language].manageSubscription}
                        onClick={() => ulmFlow({ redirectUri: ScreenNames.PRM.ManageSubscription, productId, appName: 'PRM' })}
                        />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex> }
        </Container>
    )
}