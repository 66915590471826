import React, { useEffect } from "react";
import { Flex } from "@aws-amplify/ui-react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import OutlineButton from "../../components/Buttons/OutlineButton";
import Strings from "../../constants/Strings";
import Language from "../../utils/Language";
import Container from "../../components/Containers/Default";
import Header from "../../components/Headers";
import { redirectAtt, goTo } from '../../utils/functions';
import useDeviceTypes from "../../hooks/useDeviceTypes";

const redirectUri = process.env.REACT_APP_HBO_REDIRECT_URL;
const clientId = process.env.REACT_APP_HBO_CLIENT_ID;
const url = process.env.REACT_APP_HBO_M1_URL + `?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scopes=openid%20phone&x-ulm-productId=HBOMax`;

export default function Store(props) {

    const { width, isMobile } = useDeviceTypes();

    useEffect(() => {
        document.title = Strings.HBO[Language].title;
    }, []);

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header maxWidth={width * .5} title={Strings.HBO[Language].homeTitle} subtitle={Strings.HBO[Language].homeSubTitle} titleProps={{ fontSize: 28 }} subTitleProps={{ fontSize: 30 }}/>
                <Flex direction={'column'} marginTop={isMobile ? 10 : 300}>
                    <PrimaryButton
                        children={Strings.HBO[Language].continue}
                        onClick={() => goTo(url)}
                        />
                    <OutlineButton
                        marginTop={20}
                        children={Strings.HBO[Language].cancel}
                        onClick={() => redirectAtt()}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}