import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { Flex, RadioGroupField, Radio, Text } from "@aws-amplify/ui-react";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import TextButton from "../../../components/Buttons/TextButton";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";
import Colors from "../../../constants/Colors";


export default function ChangeSubscription(props) {

    const navigate = useNavigate();

    const [products, setProducts] = useState([
        {
            id: '1',
            name: '<Product 1>',
        },
        {
            id: '2',
            name: '<Product 2>',
        },
        {
            id: '3',
            name: '<Product 3>',
        },
    ]);

    const [product, setProduct] = useState('');

    const [currentProduct, setCurrentProduct] = useState({
        id: '4',
        name: '<Product 4>',
    });

    const [error, setError] = useState(false);

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].changeSubscriptionTitle;
    }, []);

    useEffect(() => {}, []);

    const next = () => {

        if(!product) {
            setError(true);
            return false;
        }
        setError(false);

        navigate(ScreenNames.GOOGLE.ChangeSubscriptionConfirmation);
    }

    const failure = () => navigate(ScreenNames.GeneralFailure, { type: Strings.GOOGLE[Language].changeSubscriptionTitle });


    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].changeSubscriptionTitle} subtitle={Strings.GOOGLE[Language].changeSubscriptionSubTitle}/>
                <Flex marginTop={200} direction='column' gap={0}>
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small} color={Colors.primary}>
                        {Strings.GOOGLE[Language].currentSubscription}
                    </Text>
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small} marginTop={5}>
                        {currentProduct.name}
                    </Text>
                </Flex>
                <Flex marginTop={35} direction='column' gap={0}>
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small} color={Colors.primary}>
                        {Strings.GOOGLE[Language].selectNewSubscription}
                    </Text>
                    <RadioGroupField
                        gap={40}
                        size={'large'}
                        errorMessage={Strings.GOOGLE[Language].fieldRequired} 
                        hasError={error}
                        value={product}                        
                        fontSize={20}
                        onChange={(e) => setProduct(e.target.value)}>
                        {_.map(products, i => <Radio key={i.id} value={i.id}>{i.name}</Radio>)}
                    </RadioGroupField>
                </Flex>
                <Flex direction={'column'} marginTop={100}>
                    <OutlineButton
                        children={Strings.GOOGLE[Language].change}
                        onClick={next}
                        />
                    <TextButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].cancel}
                        onClick={() => navigate(ScreenNames.GOOGLE.ManageSubscription)}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}