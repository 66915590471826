import React, { useState, useEffect } from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import Colors from "../../../constants/Colors";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";
import Spinner from "../../../components/Spinners/primary";
import useMarket from "../../../hooks/useMarket";
import useDeviceTypes from "../../../hooks/useDeviceTypes";

export default function ActivateSubscription(props) {

    const { height } = useDeviceTypes();

    const { attRedirect, getCurrentPartnerSub } = useMarket();

    const [loading, setLoading] = useState(true);

    const [product, setProduct] = useState({
        id: 1,
        name: 'Product 1',
        price: '$99.99',
    });

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].activationConfirmationTitle;
    }, []);

    useEffect(() => {
        (async() => {            
            const { productData } = await getCurrentPartnerSub();
            setProduct(productData);
            setLoading(false);
        })();
    }, []);

    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].activationConfirmationTitle} subtitle={Strings.GOOGLE[Language].activationConfirmationSubTitle}/>
                <Flex alignItems={'center'} direction={'column'} marginTop={25} height={200} justifyContent='center'>                    
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.medium}>
                        {Strings.GOOGLE[Language].subscriptionActivated}
                    </Text>
                    <Text color={Colors.primary} fontFamily={Fonts.body} fontSize={TextDimensions.small}>
                        {product.descriptions.data[0].description}
                    </Text>
                </Flex>
                <Flex direction={'column'} marginTop={50} width={350} alignSelf='center'>
                    <OutlineButton
                        children={Strings.GOOGLE[Language].continue}
                        onClick={attRedirect}
                        />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex> }
        </Container>
    )
}