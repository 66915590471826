import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";

export default function PurchaseConfirmation(props) {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].purchaseConfirmationTitle;
    }, []);

    useEffect(() => {
       
    }, []);

    const next = () => {

        navigate(ScreenNames.GOOGLE.ManageSubscription);
    }

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].purchaseConfirmationTitle} subtitle={Strings.GOOGLE[Language].purchaseConfirmationSubTitle }/>
                
                <Flex direction={'column'} marginTop={150} width={350} alignSelf='center'>
                    <OutlineButton
                        children={Strings.GOOGLE[Language].continue}
                        onClick={next}
                        />
                </Flex>
            </Flex>
        </Container>
    )
}