import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Text } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import useMarket from "../../../hooks/useMarket";
import ScreenNames from "../../../constants/ScreenNames";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";

export default function PRM_CancelConfirmation(props) {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { attRedirect } = useMarket();
    // const [product] = useState(state.canceledSubscription.items.data[0].product);
    const [status] = useState(state.status);
    const [product] = useState({
        descriptions: {
            data: [
                {
                    description: 'Paramount Subscriptions'
                }
            ]
        }
    })

    useEffect(() => {
        document.title = Strings.PRM[Language].cancelConfirmationTitle;
    }, []);

    useEffect(() => { }, []);

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                {!status && <Header title={Strings.PRM[Language].cancelConfirmationTitle} subtitle={Strings.PRM[Language].cancelConfirmationSubTitle}/>}
                {status === 400 && <Header title={Strings.GOOGLE[Language].cancelConfirmationTitle} subtitle={Strings.GOOGLE[Language].cancelSubscription400.replace('<product>', 'Paramount+')}/>}
                {status === 409 && <Header title={Strings.GOOGLE[Language].cancelConfirmationTitle} subtitle={Strings.GOOGLE[Language].cancelSubscription409.replace('<product>', 'Paramount+')}/>}

                <Flex alignItems={'center'} marginTop={25} width={350} alignSelf={'center'} >
                    <Text fontFamily={Fonts.body} textAlign="center" fontSize={TextDimensions.small}>
                        {Strings.PRM[Language].cancelConfirmationThirdTitle}
                    </Text>
                </Flex>
                <Flex direction={'column'} marginTop={150} width={350} alignSelf='center'>
                    <OutlineButton
                        children={Strings.PRM[Language].continue}
                        onClick={attRedirect}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}