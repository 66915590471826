import React, { useEffect } from "react";
import { Flex, View } from "@aws-amplify/ui-react";
import Title from "./Title";
import SubTitle from "./SubTitle";
import useDeviceTypes from "../../hooks/useDeviceTypes";

export default function Header(props) {

    const { height } = useDeviceTypes();

    useEffect(() => {}, []);

    return (
        <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} gap={10} alignSelf="center" top={0}>
            <Title children={props.title ? props.title : 'Title'} {...props.titleProps}/>
            {props.subtitle ? <SubTitle children={props.subtitle} {...props.subTitleProps} /> : <View height={25} />}
        </Flex>
    )
}