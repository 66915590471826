import React, { useState, useEffect } from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";
import Colors from "../../../constants/Colors";
import Spinner from "../../../components/Spinners/primary";
import useMarket from "../../../hooks/useMarket";
import useDeviceTypes from "../../../hooks/useDeviceTypes";


export default function CancelConfirmationOther(props) {

    const { height } = useDeviceTypes();

    const { getCurrentPartnerSub, cancelSubscription } = useMarket();

    const [product, setProduct] = useState({});

    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].cancelConfirmationOtherTitle;
    }, []);

    useEffect(() => {
        (async() => {
            const { productData, partnerSubscription } = await getCurrentPartnerSub();
            setProduct(productData);
            setSubscription(partnerSubscription);
            await cancelSubscription({ id: partnerSubscription.subscriptionId, dryRun: true });
            setLoading(false);
        })();
    }, []);

    const next = async() => {
        setApiLoading(true);
        await cancelSubscription({ id: subscription.subscriptionId, dryRun: false, redirect: true });
        setApiLoading(false);
    }

    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].cancelConfirmationOtherTitle} subtitle={Strings.GOOGLE[Language].cancelConfirmationOtherSubTitle}/>
                <Flex alignItems={'center'} direction={'column'} marginTop={25} height={200} justifyContent='center'>                    
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small}>
                        {Strings.GOOGLE[Language].subscriptionCancelled}
                    </Text>
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small} color={Colors.primary}>
                        {product.descriptions.data[0].description}
                    </Text>
                    {/* <Text fontFamily={Fonts.body} fontSize={TextDimensions.small} marginTop={30} color={Colors.red}>
                        {Strings.GOOGLE[Language].customerWillNotBeCharged}
                    </Text> */}
                </Flex>
                <Flex direction={'column'} marginTop={50} width={350} alignSelf='center'>
                    <OutlineButton
                        disabled={apiLoading}
                        isLoading={apiLoading}
                        children={Strings.GOOGLE[Language].okay}
                        onClick={next}
                        />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex> }
        </Container>
    )
}