import React, { useState, useEffect,  } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, CheckboxField, ScrollView, Text } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";
import Colors from "../../../constants/Colors";

export default function ChangeSubscriptionConfirmation(props) {

    const navigate = useNavigate();

    const [product, setProduct] = useState({
        id: 1,
        name: 'Product 1',
        price: '$99.99',
    });

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].changeSubscriptionConfirmationTitle;
    }, []);

    useEffect(() => {}, []);

    const next = () => {

        window.location.href = 'https://www.att.com.mx/entretenimiento';
    }


    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].changeSubscriptionConfirmationTitle} subtitle={Strings.GOOGLE[Language].changeSubscriptionConfirmationSubTitle}/>
                <Flex alignItems={'center'} direction={'column'} marginTop={25} height={200} justifyContent='center'>                    
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.medium}>
                        {Strings.GOOGLE[Language].currentSubscription}
                    </Text>
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small} color={Colors.primary}>
                        {product.name}
                    </Text>
                </Flex>
                <Flex direction={'column'} marginTop={50} width={350} alignSelf='center'>
                    <OutlineButton
                        children={Strings.GOOGLE[Language].continue}
                        onClick={next}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}