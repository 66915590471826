import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import PrimaryButton from "../Buttons/PrimaryButton";
import Strings from "../../constants/Strings";
import Language from "../../utils/Language";
import useDeviceTypes from "../../hooks/useDeviceTypes";

export default function Container(props) {

    const { width, height } = useDeviceTypes();
    useEffect(() => {}, []);

    return (
        <Flex {...props} flex={1} direction={'row'} justifyContent={'center'} width={width} padding={30}/>
    )
}