import React, { useEffect } from "react";
import { Button } from "@aws-amplify/ui-react";
import Strings from "../../constants/Strings";
import Language from "../../utils/Language";
import TextDimensions from "../../constants/TextDimensions";
import Fonts from '../../constants/Fonts';
import Colors from "../../constants/Colors";

export default function TextButton(props) {
    useEffect(() => {}, []);

    return (
        <Button
            {...props}
            loadingText={Strings.GOOGLE[Language].loading}
            fontSize={TextDimensions.small}
            minWidth={300}
            borderRadius={50}
            variation={'link'}
            fontFamily={Fonts["ATTAleckSans-Rg"]}
            color={Colors.primary}
            maxWidth={300}
            alignSelf={'center'}
            />
    )
}