import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import useToken from "../../../hooks/useToken";
import Spinner from "../../../components/Spinners/primary";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import useMarket from "../../../hooks/useMarket";

export default function PRMManageSubscription(props) {

    const navigate = useNavigate();
    const { height } = useDeviceTypes();    

    const { getTokenData } = useToken();

    const { ulmFlow, getToken, getCurrentPartnerSub, paramountCodeRedirect } = useMarket();

    const params = new URLSearchParams(window.location.search);
    const [code] = useState(params.get("code"));
    const [productId] = useState(process.env.REACT_APP_PRODUCT_ID || 'paramountpluslatam');
    const [loading, setLoading] = useState(true);

    const [status, setStatus] = useState(''); //Activating, Activated, Suspended, Deactivated, Pending, Other

    const [subscription, setSubscription] = useState({});
    
    useEffect(() => {
        document.title = Strings.PRM[Language].manageSubscriptionTitle;
    }, []);

    
    useEffect(() => {
        (async() => {            

            //If no token stored, redirect to ulm
            //if(!getTokenData()?.access_token && !code) await ulmFlow({ redirectUri: ScreenNames.PRM.ManageSubscription, productId: productId, appName: 'PRM' });
            if(!getTokenData()?.access_token && !code) navigate(ScreenNames.PRM.AuthenticationFailure);

            //If no token or code, get token data
            if(!getTokenData()?.access_token && code) await getToken(code, ScreenNames.PRM.ManageSubscription, 'PRM');

            //Get user
            // await getUserInfo();

            //Get partner subs
            const { partnerSubscription } = await getCurrentPartnerSub(false, 'PRM');

            setSubscription(partnerSubscription);
            
            setStatus(partnerSubscription?.serviceStatus);
            
            setLoading(false);
            
        })();
    }, []);

    useEffect(() => {
        if((status === 'CANCELLED')) navigate(ScreenNames.PRM.Purchase);
    }, [status]);


    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.PRM[Language].manageSubscriptionTitle} subtitle={''}/>
                <Flex direction={'column'} marginTop={150}>
                    {status === 'INACTIVE' && <PrimaryButton
                        children={Strings.PRM[Language].activateSubscription}
                        onClick={() => paramountCodeRedirect(subscription.partnerTokenizedUrl)}
                        />}
                    {status === 'UNPROVISIONED' && <PrimaryButton
                        children={Strings.PRM[Language].activateSubscription}
                        onClick={() => paramountCodeRedirect(subscription.partnerTokenizedUrl)}
                        />}
                    {status === 'UNPROVISIONED' && <PrimaryButton
                        marginTop={20}
                        children={Strings.PRM[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.PRM.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />}
                    {status === 'INACTIVE' && <PrimaryButton
                        marginTop={20}
                        children={Strings.PRM[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.PRM.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />}
                    {status === 'ACTIVE' && <PrimaryButton
                        marginTop={20}
                        children={Strings.PRM[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.PRM.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />}
                    {status === 'CANCELLATION-IN-PROGRESS' && <PrimaryButton
                        marginTop={20}
                        children={Strings.PRM[Language].cancelSubscription}
                        onClick={() => navigate(ScreenNames.PRM.CancelSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />}
                    {/* {status === 'ACTIVE' && <OutlineButton
                        marginTop={20}
                        children={Strings.PRM[Language].changeSubscription}
                        onClick={() => navigate(ScreenNames.PRM.ChangeSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />}
                    {status === 'INACTIVE' && <OutlineButton
                        marginTop={20}
                        children={Strings.PRM[Language].changeSubscription}
                        onClick={() => navigate(ScreenNames.PRM.ChangeSubscription + '?subscriptionId=' + subscription.subscriptionId, { subscription } )}
                        />} */}
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex>} 
        </Container>
    )
}