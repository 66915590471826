import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { View } from "@aws-amplify/ui-react";

export default function ConfirmCancellation(props) {
    useEffect(() => {}, []);

    return (
        <></>
    )
}