import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { Flex, RadioGroupField, Radio, Text } from "@aws-amplify/ui-react";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import TextButton from "../../../components/Buttons/TextButton";
import Spinner from "../../../components/Spinners/primary";
import useMarket from "../../../hooks/useMarket";

export default function SelectProduct(props) {

    const navigate = useNavigate();

    const { fetchProducts, ulmFlow, setProductId } = useMarket();

    const [loading, setLoading] = useState(true);

    const [products, setProducts] = useState([
        {
            id: '',
            descriptions: {
                data: [
                    {
                        description: 'none',
                    }
                ]
            }
        }
    ]);

    const [product, setProduct] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].googleOne + ' ' + Strings.GOOGLE[Language].purchaseTitle;
    }, []);

    useEffect(() => {
        (async() => {

            const prods = await fetchProducts();
            setProducts(prods);
            setLoading(false);

        })();
    },[]);

    useEffect(() => {}, []);

    const next = async() => {

        if(!product) {
            setError(true);
            return false;
        }
        setError(false);

        //save product id to cache to get after ulmflow
        setProductId(product);

        await ulmFlow({ redirectUri: ScreenNames.GOOGLE.AcceptTerms, productId: product });
    }

    const radioItem = (i) => {
        console.log(i)
        const description = i?.descriptions.data[0].description;
        return (
            <Radio key={i.id} value={i.id}>
                <Text>{description}</Text>
                {i.id === 'GOOGLE1_100GB' && <Text fontSize={15} fontWeight={'bold'} textAlign={'right'}>{'Primer mes incluido'}</Text>}
            </Radio>
        )
    }

    return (
        <Container minHeight={'90vh'}>
            <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].googleOne} subtitle={Strings.GOOGLE[Language].purchaseSubTitle}/>
                <Flex >
                    {!loading ? <RadioGroupField
                        gap={40}
                        size={'large'}
                        errorMessage={Strings.GOOGLE[Language].fieldRequired} 
                        hasError={error}
                        value={product}                        
                        fontSize={20}
                        onChange={(e) => setProduct(e.target.value)}>
                        {_.map(products, i => radioItem(i))}
                    </RadioGroupField> : <Flex style={{ alignContent: 'center', justifyContent: 'center', width: '100%' }}><Spinner style={{ alignSelf: 'center' }} /></Flex>}
                </Flex>
                <Flex direction={'column'} marginTop={100}>
                    <OutlineButton
                        children={Strings.GOOGLE[Language].purchase}
                        onClick={next}
                        />
                    <TextButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].cancel}
                        onClick={() => navigate(ScreenNames.GOOGLE.Store)}
                        />
                </Flex>
            </Flex> 
        </Container>
    )
}