import React, { useEffect } from "react";
import { Button, Text, View, Flex, Image } from "@aws-amplify/ui-react";
import Spinner from "../Spinners/primary";
import { Oval } from  'react-loader-spinner';
import useDeviceTypes from "../../hooks/useDeviceTypes";
import Colors from "../../constants/Colors";

export default function GoogleSignInButton(props) {
    
    const { height } = useDeviceTypes();

    return (
        <View style={props.style}>
            <Button                
                color={'#444'}
                borderRadius={'5px'}
                border={'thin solid #ddd'}
                backgroundColor={'white'}
                paddingVertical={8}
                paddingLeft={8}
                disabled={props.disabled}
                isLoading={props.isLoading}
                onClick={() => props.onClick()}
                maxWidth={'400px'}
                children={
                    <Flex direction={'row'} width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Image style={{
                            verticalAlign: 'middle',
                            display: 'inline-block',
                            width: 18,
                            height: 18,
                            left: 0
                        }} src={require('../../assets/images/google.png')} />
                        {!props.isLoading ? <Text fontFamily={'Roboto-Medium'} fontWeight={'light'} fontSize={14} color={'rgba(0,0,0,.54)'}>{'Activar con Google'}</Text>
                        : <Oval
                        height = "20"
                        width = "20"
                        color = {Colors.primary}
                        ariaLabel = 'three-dots-loading'
                        />}
                    </Flex>
                }
            />
        </View>
    )
}