import React, { useState, useEffect,  } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Text } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import TextButton from "../../../components/Buttons/TextButton";
import Fonts from "../../../constants/Fonts";
import TextDimensions from "../../../constants/TextDimensions";
import Spinner from "../../../components/Spinners/primary";
import useMarket from "../../../hooks/useMarket";
import useDeviceTypes from "../../../hooks/useDeviceTypes";

export default function CancelSubscription(props) {

    const navigate = useNavigate();

    // const { state } = useLocation();

    const { height } = useDeviceTypes();

    const { getCurrentPartnerSub, cancelSubscription } = useMarket();    

    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({});
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        document.title = Strings.GOOGLE[Language].cancelSubscriptionTitle;
    }, []);

    useEffect(() => {
        (async() => {
            const { partnerSubscription, productData } = await getCurrentPartnerSub();
            // if(partnerSubscription?.serviceStatus === 'INACTIVE') navigate(ScreenNames.GOOGLE.GeneralFailure);
            setSubscription(partnerSubscription);
            setProduct(productData);
            // await cancelSubscription({ id: partnerSubscription.subscriptionId, dryRun: true, partnerSubscription, product: productData });
            setLoading(false);
        })();
    }, []);

    const next = async() => {

        const canceledSubscription = await cancelSubscription({ id: subscription.subscriptionId, dryRun: false, redirect: false, partnerSubscription: subscription, product });

        navigate(ScreenNames.GOOGLE.CancelConfirmation, { state: { subscription, canceledSubscription }});
    }

    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                <Header title={Strings.GOOGLE[Language].cancelSubscriptionTitle} subtitle={''}/>
                <Flex alignItems={'center'} direction={'column'} marginTop={25} height={200} justifyContent='center'>                    
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.medium}>
                        {Strings.GOOGLE[Language].currentSubscription}
                    </Text>
                    <Text fontFamily={Fonts.body} fontSize={TextDimensions.small}>
                        {product.descriptions.data[0].description}
                    </Text>
                </Flex>
                <Flex direction={'column'} marginTop={50} width={350} alignSelf='center'>
                    <OutlineButton
                        children={Strings.GOOGLE[Language].cancelSubscription}
                        onClick={() => next()}
                        />
                    <TextButton
                        marginTop={20}
                        children={Strings.GOOGLE[Language].goBack}
                        onClick={() => navigate(ScreenNames.GOOGLE.ManageSubscription)}
                        />
                </Flex>
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex> }
        </Container>
    )
}