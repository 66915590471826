// import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { useEffect, useState } from 'react';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        await Font.loadAsync({
          //...FontAwesome.font,
          //ATTAleckCd
          'ATTAleckCd-Bold': require('../assets/fonts/ATTAleckCd/ATTAleckCd_Bd.ttf'),
          'ATTAleckCd-BdIt': require('../assets/fonts/ATTAleckCd/ATTAleckCd_BdIt.ttf'),
          'ATTAleckCd-Blk': require('../assets/fonts/ATTAleckCd/ATTAleckCd_Blk.ttf'),
          'ATTAleckCd-BlkIt': require('../assets/fonts/ATTAleckCd/ATTAleckCd_BlkIt.ttf'),
          'ATTAleckCd-It': require('../assets/fonts/ATTAleckCd/ATTAleckCd_It.ttf'),
          'ATTAleckCd-Lt': require('../assets/fonts/ATTAleckCd/ATTAleckCd_Lt.ttf'),
          'ATTAleckCd-LtIt': require('../assets/fonts/ATTAleckCd/ATTAleckCd_LtIt.ttf'),
          'ATTAleckCd-Md': require('../assets/fonts/ATTAleckCd/ATTAleckCd_Md.ttf'),
          'ATTAleckCd-MdIt': require('../assets/fonts/ATTAleckCd/ATTAleckCd_MdIt.ttf'),
          'ATTAleckCd-Rg': require('../assets/fonts/ATTAleckCd/ATTAleckCd_Rg.ttf'),
          //ATTAleckSans
          'ATTAleckSans-Bd': require('../assets/fonts/ATTAleckSans/ATTAleckSans_Bd.ttf'),
          'ATTAleckSans-BdIt': require('../assets/fonts/ATTAleckSans/ATTAleckSans_BdIt.ttf'),
          'ATTAleckSans-Blk': require('../assets/fonts/ATTAleckSans/ATTAleckSans_Blk.ttf'),
          'ATTAleckSans-BlkIt': require('../assets/fonts/ATTAleckSans/ATTAleckSans_BlkIt.ttf'),
          'ATTAleckSans-It': require('../assets/fonts/ATTAleckSans/ATTAleckSans_It.ttf'),
          'ATTAleckSans-Lt': require('../assets/fonts/ATTAleckSans/ATTAleckSans_Lt.ttf'),
          'ATTAleckSans-LtIt': require('../assets/fonts/ATTAleckSans/ATTAleckSans_LtIt.ttf'),
          'ATTAleckSans-Md': require('../assets/fonts/ATTAleckSans/ATTAleckSans_Md.ttf'),
          'ATTAleckSans-MdIt': require('../assets/fonts/ATTAleckSans/ATTAleckSans_MdIt.ttf'),
          'ATTAleckSans-Rg': require('../assets/fonts/ATTAleckSans/ATTAleckSans_Rg.ttf'),
          'ATTAleckSans-Th': require('../assets/fonts/ATTAleckSans/ATTAleckSans_Th.ttf'),
          'ATTAleckSans-ThIt': require('../assets/fonts/ATTAleckSans/ATTAleckSans_ThIt.ttf'),
          //
          'Roboto-Medium': require('../assets/fonts/Roboto/Roboto-Medium.ttf'),

        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}