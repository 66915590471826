import axios from 'axios';
import _ from 'lodash';

const { REACT_APP_API_URL } = process.env;

export const apiAccounts = {
    get: (async ( id ) => {
        const tokenString = localStorage.getItem('token_data');
        const tokenData = JSON.parse(tokenString);
        const request = {
            method: 'get',
            baseURL: REACT_APP_API_URL,
            url: '/accounts/' + id,
            headers: {
                'Authorization': 'Bearer ' + _.get(tokenData, 'access_token') 
            }
        };
        
        return await axios( request );
    }),
}