import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import useCachedResources from "./hooks/useCacheResources";
import Screens from "./constants/Screens";
import ScreenNames from "./constants/ScreenNames";
import Nav from './components/Nav';
import { Flex } from "@aws-amplify/ui-react";
import Colors from './constants/Colors';
// import Footer from './components/Footer';



export default function App() {

  const isLoadingComplete = useCachedResources();

  useEffect(() => {
    if(window.location.pathname === '/') window.location.href = 'https://www.att.com.mx/entretenimiento';
  },[])

  if(!isLoadingComplete){
    return <Flex />
  }
  else
  return (
    <Flex backgroundColor={Colors.surface} direction={'column'} gap={0}>
      <Nav />
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          {/* ROUTES */}
          {/* <Route path={ScreenNames.General.Home} element={<Screens.GOOGLE.G_Store/>} /> */}
          {/* GOOGLE */}
          <Route path={ScreenNames.GOOGLE.Store} element={<Screens.GOOGLE.G_Store/>} />
          <Route path={ScreenNames.GOOGLE.SelectProduct} element={<Screens.GOOGLE.G_SelectProduct />} />
          <Route path={ScreenNames.GOOGLE.AcceptTerms} element={<Screens.GOOGLE.G_AcceptTerms />} />
          <Route path={ScreenNames.GOOGLE.PurchaseConfirmation} element={<Screens.GOOGLE.G_PurchaseConfirmation />} />
          <Route path={ScreenNames.GOOGLE.GeneralFailure} element={<Screens.GOOGLE.G_GeneralFailure />} />
          <Route path={ScreenNames.GOOGLE.ManageSubscription} element={<Screens.GOOGLE.G_ManageSubscription />} />
          <Route path={ScreenNames.GOOGLE.CancelSubscription} element={<Screens.GOOGLE.G_CancelSubscription />} />
          <Route path={ScreenNames.GOOGLE.CancelConfirmation} element={<Screens.GOOGLE.G_CancelConfirmation />} />
          <Route path={ScreenNames.GOOGLE.CancelConfirmationOther} element={<Screens.GOOGLE.G_CancelConfirmationOther />} />
          <Route path={ScreenNames.GOOGLE.Authentication} element={<Screens.GOOGLE.G_Authentication />} />
          <Route path={ScreenNames.GOOGLE.AuthenticationFailure} element={<Screens.GOOGLE.G_AuthenticationFailure />} />
          <Route path={ScreenNames.GOOGLE.Purchase} element={<Screens.GOOGLE.G_Purchase />} />
          <Route path={ScreenNames.GOOGLE.ChangeSubscription} element={<Screens.GOOGLE.G_ChangeSubscription />} />
          <Route path={ScreenNames.GOOGLE.ChangeSubscriptionConfirmation} element={<Screens.GOOGLE.G_ChangeSubscriptionConfirmation />} />
          <Route path={ScreenNames.GOOGLE.ActivateSubscription} element={<Screens.GOOGLE.G_ActivateSubscription />} />
          <Route path={ScreenNames.GOOGLE.LoginWithGoogle} element={<Screens.GOOGLE.G_LoginWithGoogle />} />
          <Route path={ScreenNames.GOOGLE.GoogleError} element={<Screens.GOOGLE.G_GoogleError />} />
          <Route path={ScreenNames.GOOGLE.ActivationConfirmation} element={<Screens.GOOGLE.G_ActivationConfirmation />} />
          <Route path={ScreenNames.GOOGLE.ConfirmCancellation} element={<Screens.GOOGLE.G_ConfirmCancellation />} />
          <Route path={ScreenNames.GOOGLE.Request} element={<Screens.GOOGLE.G_Request />} />
          <Route path="/google1/*" element={<Screens.GOOGLE.G_Error404 />} />

          {/* HBO */}
          <Route path={ScreenNames.HBO.Home} element={<Screens.HBO.HBO_Home/>} />
          <Route path={ScreenNames.HBO.Request} element={<Screens.HBO.HBO_Request/>} />
          <Route path="/max/*" element={<Screens.HBO.HBO_Error404 />} />

          {/* PRM */}
          <Route path={ScreenNames.PRM.Home} element={<Screens.PRM.PRM_LandingPage/>} />
          <Route path={ScreenNames.PRM.ManageSubscription} element={<Screens.PRM.PRM_ManageSubscription/>} />
          <Route path={ScreenNames.PRM.CancelSubscription} element={<Screens.PRM.PRM_CancelSubscription/>} />
          <Route path={ScreenNames.PRM.PurchaseInstead} element={<Screens.PRM.PRM_PurchaseInstead/>} />
          <Route path={ScreenNames.PRM.CancelConfirmation} element={<Screens.PRM.PRM_CancelConfirmation/>} />
          <Route path={ScreenNames.PRM.AcceptTerms} element={<Screens.PRM.PRM_AcceptTerms/>} />
          <Route path={ScreenNames.PRM.PurchaseConfirmation} element={<Screens.PRM.PRM_PurchaseConfirmation/>} />
          <Route path={ScreenNames.PRM.GeneralFailure} element={<Screens.PRM.PRM_GeneralFailure/>} />
          <Route path={ScreenNames.PRM.AuthenticationFailure} element={<Screens.PRM.PRM_AuthenticationFailure/>} />
          <Route path={ScreenNames.PRM.Purchase} element={<Screens.PRM.PRM_PurchaseInstead/>} />
          <Route path="/paramountplus/*" element={<Screens.PRM.PRM_Error404 />} />


        </Routes>
      </Router>
      {/* <Footer /> */}
    </Flex>
  );
}

