import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import Strings from "../../../constants/Strings";
import Language from "../../../utils/Language";
import Container from "../../../components/Containers/Default";
import ScreenNames from "../../../constants/ScreenNames";
import Header from "../../../components/Headers";
import Spinner from "../../../components/Spinners/primary";
import useDeviceTypes from "../../../hooks/useDeviceTypes";
import axios from "axios";
import { goTo, redirectAtt } from "../../../utils/functions";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

const url = process.env.REACT_APP_HBO_MIDDLEWARE_URL;

export default function Request(props) {

    const navigate = useNavigate();
    const { height } = useDeviceTypes();

    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(null);

    const params = new URLSearchParams(window.location.search);
    const [code] = useState(params.get("code"));

    useEffect(() => {
      document.title = Strings.HBO[Language].title;
    }, []);

    const sendPostRequest = async () => {
      try {
        const results = await axios.post(url, {
          authCode: code
        })
        setResponse(results.data.statusCode);
        setLoading(false);
      } catch (err) {
        setResponse(504);
        setLoading(false);
      }
    };

    const renderResponseText = (statusCode) => {
        switch (statusCode) {
            case '200':
            case '00':
              return (
                <>
                <Header title={`¡Tu suscripción a Max Estándar se realizó con éxito!`} subtitle={`Ahora, crea tu cuenta en Max y ¡disfruta del contenido!`} titleFontSize={28} subTitleFontSize={0}/>
                <Flex direction={'column'} marginTop={300}>
                    <PrimaryButton
                        children={Strings.HBO[Language].continue}
                        onClick={() => goTo('https://www.hbomax.com/mx/es')}
                        />
                    {/* <OutlineButton
                        marginTop={20}
                        children={Strings.HBO[Language].cancel}
                        onClick={() => redirectAtt()}
                        /> */}
                </Flex>
                </>
              );
            case '01':
              return (
                <>
                <Header title={`¡Lo sentimos mucho! Tu número no es AT&T`} subtitle={`Cambia tu número a AT&T.`} titleFontSize={28} subTitleFontSize={0}/>
                <Flex direction={'column'} marginTop={300}>
                    <PrimaryButton
                        children={Strings.HBO[Language].continue}
                        onClick={() => goTo('https://www.att.com.mx/servicios/portabilidad.html')}
                        />
                </Flex>
                </>
              );
            case '05':
              return (
                <>
                <Header title={`¡Oh Oh!`} subtitle={`Este número ya tiene contratado una suscripción de Max`} titleFontSize={28} subTitleFontSize={0}/>
                <Flex direction={'column'} marginTop={300}>
                    <PrimaryButton
                        children={Strings.HBO[Language].continue}
                        onClick={() => goTo('https://www.hbolatam.com/mx')}
                        />
                </Flex>
                </>
              )
            case '08':
              return (
                 <>
                 <Header title={`¡Lo sentimos mucho! Tu plan no permite la contratación de éste contenido.  Consulta más información dando clic en continuar.`} titleFontSize={28} subTitleFontSize={0}/>
                 <Flex direction={'column'} marginTop={300}>
                     <PrimaryButton
                        children={Strings.HBO[Language].continue}
                        onClick={() => redirectAtt()}
                        />
                 </Flex>
                 </>
              )
            case '01':
            case '02':
            case '03':
            case '04':
            case '06':
            case '07':
            case '99':  // Rebar unknown error from ESB - CM
            case '504': // Rebar unhandled error - CM
              return (
                <>
                <Header title={`¡Ups!`} subtitle={`Algo salió mal, por favor inténtalo más tarde.`} titleFontSize={28} subTitleFontSize={0}/>
                <Flex direction={'column'} marginTop={300}>
                    <PrimaryButton
                        children={Strings.HBO[Language].continue}
                        onClick={() => goTo('/max')}
                        />
                </Flex>
                </>
              )
          }
    }

    useEffect(() => {
        (async() => {
            if(!code) navigate(ScreenNames.HBO.Home)
            else await sendPostRequest();            
        })();
    },[]);

    return (
        <Container minHeight={'90vh'}>
            {!loading ? <Flex direction={'column'}>
                { response ? renderResponseText(response) : null }               
            </Flex> : <Flex style={{ alignItems: 'center', justifyContent: 'center', width: '100vw'}}><Spinner height="100" width="100" style={{ alignSelf: 'center', justifyContent: 'center', top: height * .2 }} /></Flex>}
        </Container>
    )
}